import axios from 'axios'
import { saveAs } from 'file-saver'

const state = {
  contacts: [],
  filter: null,
  page: 0,
  sort: 'stream', // stream | az
  filtersOpen: false,
  contactFilesCount: 0
}
const getters = {
  allContacts: (state) => state.contacts,
  filter: (state) => state.filter,
  page: (state) => state.page,
  sort: (state) => state.sort,
  filtersOpen: (state) => state.filtersOpen,
  contactFilesCount: (state) => state.contactFilesCount
}
const actions = {
  async fetchContacts ({ commit, rootState }, query) {
    const filter = query.filter

    if (query.page !== undefined) {
      state.page = query.page
    }
    query.page = state.page
    query.sort = state.sort

    if (query.download) {
      return axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact`, {
        headers: {
          token: rootState.sessions.userIsAutenticate
        },
        params: query
      }).then((response) => {}).catch((response) => {
        console.error('Could not Download the Excel report from the backend.', response)
      })
    }

    if (query.count) {
      return axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact`, {
        headers: {
          token: rootState.sessions.userIsAutenticate
        },
        params: query
      }).then((response) => {
        return response.data.count
      }).catch((response) => {
        console.error('Could not get the contacts count from the backend.', response)
      })
    }

    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      params: query
    })
    const contactListEl = document.getElementById('contact-list')
    if (contactListEl) {
      contactListEl.scrollTop = 0
    }

    commit('setFilter', filter)
    commit('setContacts', res.data)

    if (filter === 7 && res.data.length === 0) {
      await commit('setNewMessageForm', 'internal')
      commit('toggleNewMessage')
    }

    // commit('setSelectedContact', null)
  },
  async fetchContactsNextPage ({ commit, dispatch, rootState }, query) {
    commit('setPage', state.page + 1)

    query.page = state.page
    query.sort = state.sort

    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      params: query
    })

    commit('addContacts', res.data)
    return res.data.length > 0
  },
  async fetchTeamContacts ({ commit, rootState }, teamId) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact?team=${teamId}&page=${state.page}&filter=1`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    const contactListEl = document.getElementById('contact-list')
    if (contactListEl) {
      contactListEl.scrollTop = 0
    }

    commit('setFilter', teamId)
    commit('setContacts', res.data)

    // commit('setSelectedContact', null)
  },
  async fetchContact ({ commit, rootState }, contactId) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/${contactId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    return res.data
  },
  async fetchMentions ({ commit, rootState }, filter) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/mentions`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setFilter', filter)
    commit('setContacts', res.data)
    commit('setSelectedContact', null)
  },
  async setCurrentContactMentionsAsRead ({ commit, rootState }, contactId) {
    await axios.get(`${process.env.VUE_APP_SERVERHOST}api/mentions/${contactId}/read`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setContactMentionAsRead', contactId)
  },
  async toggleStarredContact ({ commit, rootState }, updContact) {
    await axios.put(`${process.env.VUE_APP_SERVERHOST}api/contact/${updContact.id}/starred`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', updContact)

    if (updContact.stage_id !== null) {
      window.emitter.emit('updateFunnelStage', updContact.stage_id)
    }
  },
  async assignContactToLoggedUser ({ commit, rootState }, contact) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/assign`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async assignContactToUser ({ commit, rootState }, { contact, user }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/assign/${user.id}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async unassignContact ({ commit, rootState }, contact) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/unassign`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async closeContact ({ commit, dispatch, rootState }, { contact, selectNext }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/close`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('closeContact', res.data)
    commit('setMessages', null)

    if (!selectNext) {
      return
    }

    const index = state.contacts.findIndex(contact => Boolean(contact.open) === true)
    if (index !== -1) {
      dispatch('fetchConversation', { contact: state.contacts[index], messageId: null })
    } else {
      commit('setSelectedContact', null)
    }
  },
  async markAsUnread ({ commit, rootState }, contact) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/unread`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('addUnreadCounter', contact)
    })
  },
  async openContact ({ commit, dispatch, rootState }, contact) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/open`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async findContactByPhone ({ commit, rootState }, phone) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/phone/${phone}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async startConversation ({ commit, dispatch, rootState }, phone) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/start/${phone}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    if (location.pathname.includes('chats') ||
      location.pathname.includes('conversation')) {
      commit('addContact', res.data)
      dispatch('fetchConversation', { contact: res.data, messageId: null })
    } else {
      window.emitter.emit('openContactOverlay', res.data.id)
    }

    dispatch('closeNewMessage')
  },
  async startConversationInternal ({ commit, dispatch, rootState }, user) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/internal/start/${user.id}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('addContact', res.data)
    dispatch('fetchConversation', { contact: res.data, messageId: null })
    dispatch('closeNewMessage')
  },
  async setContactUpdatedToNowOnNewMessage ({ commit, dispatch }, { contactId, lastUserId }) {
    if (!state.contacts) {
      return
    }

    const index = state.contacts.findIndex(contact => contact.id === contactId)
    if (index !== -1) {
      const updContact = state.contacts[index]
      updContact.updatedAt = new Date().toISOString()

      if (lastUserId !== null && lastUserId !== undefined) {
        updContact.last_user_id = lastUserId
      }

      commit('updateContact', updContact)
    } else {
      if (state.filter === 1 && (state.filtersOpen === true || state.sort === 'az')) {
        return
      }

      if (Number.isInteger(state.filter)) {
        dispatch('fetchContacts', { filter: state.filter, page: 0 })
      } else {
        dispatch('fetchTeamContacts', state.filter)
      }
    }
  },
  async updateContact ({ commit, dispatch, rootState }, contact) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}`, contact, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})

    commit('updateContact', contact)
  },
  async addContactTag ({ commit, rootState }, { contact, tag }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/tag/${tag.id}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async deleteContactTag ({ commit, rootState }, { contact, tag }) {
    const res = await axios.delete(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/tag/${tag.relation_id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async addContactTeam ({ commit, rootState }, { contact, team }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/team/${team.id}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async deleteContactTeam ({ commit, rootState }, { contact, team }) {
    const res = await axios.delete(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/team/${team.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateContact', res.data)
    commit('setSelectedContact', res.data)
  },
  async getContactFileList ({ commit, rootState }, contact) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/mediafiles/list`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async deleteContact ({ commit, dispatch, rootState }, contact) {
    await axios.delete(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('removeContact', contact)

      const index = state.contacts.findIndex(contact => Boolean(contact.open) === true)
      if (index !== -1) {
        dispatch('fetchConversation', { contact: state.contacts[index], messageId: null })
      } else {
        commit('setSelectedContact', null)
      }
    }).catch(() => {})
  },
  async getContactFileCount ({ commit, rootState }, contact) {
    axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/mediafiles/count`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(res => commit('setContactFilesCount', res.data.count))
      .catch(() => commit('setContactFilesCount', 0))
  },
  async exportContactConversationDownload ({ commit, rootState }, { contactId }) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/${contactId}/export/download`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      responseType: 'blob'
    }).then((response) => {
      saveAs(response.data, `conversation-${contactId}-${new Date().getTime()}.txt`)
    }).catch((response) => {
      console.error('Could not Download the Excel report from the backend.', response)
    })
  },
  async exportContactConversationEmail ({ commit, rootState }, { contactId, data }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/contact/${contactId}/export/email`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  clearContacts ({ commit }) {
    commit('setContacts', [])
  },
  setFilterView ({ commit }, value) {
    commit('setFilter', value)
  },
  setPage ({ commit }, page) {
    commit('setPage', page)
  },
  setSort ({ commit }, sort) {
    commit('setSort', sort)
  },
  goUpContactById ({ commit }, contactId) {
    commit('goUpContactById', contactId)
  },
  setFiltersOpen ({ commit }, open) {
    commit('setFiltersOpen', open)
  },
  downloadExcel ({ commit, rootState }, id) {
    if (id) {
      return axios.get(`${process.env.VUE_APP_SERVERHOST}api/contact/excel/${id}`, {
        headers: {
          token: rootState.sessions.userIsAutenticate
        },
        responseType: 'blob'
      }).then((response) => {
        saveAs(response.data, 'export_manycontacts.xlsx')
      }).catch((response) => {
        console.error('Could not Download the Excel report from the backend.', response)
      })
    }
  }
}
const mutations = {
  setFilter: (state, filter) => (state.filter = filter),
  setPage: (state, page) => (state.page = page),
  setSort: (state, sort) => (state.sort = sort),
  setFiltersOpen: (state, open) => (state.filtersOpen = open),
  setContacts: (state, contacts) => (state.contacts = contacts),
  setContactFilesCount: (state, count) => (state.contactFilesCount = count),
  addContacts: (state, contacts) => {
    state.contacts = state.contacts.concat(contacts)
  },
  addContact: (state, newContact) => {
    const index = state.contacts.findIndex(contact => contact.id === newContact.id)
    if (index === -1) {
      state.contacts.push(newContact)
    }
  },
  updateContact: (state, updContact) => {
    const index = state.contacts.findIndex(contact => contact.id === updContact.id)
    if (index !== -1) {
      state.contacts.splice(index, 1, updContact)
    }
  },
  updateContactDelta: (state, { updContact, delta }) => {
    const index = state.contacts.findIndex(contact => contact.id === updContact.id)
    if (index !== -1) {
      const master = state.contacts[index]
      Object.entries(delta).forEach(([key, value]) => {
        master[key] = value
      })

      state.contacts.splice(index, 1, master)
    }
  },
  closeContact: (state, updContact) => {
    const indexContact = state.contacts.findIndex(contact => contact.id === updContact.id)
    if (indexContact !== -1) {
      const element = state.contacts.splice(indexContact, 1)
      element.open = 0
      state.contacts.push(element)
    }
  },
  goUpContactById: (state, contactId) => {
    if (state.contacts && state.contacts.length > 0) {
      const index = state.contacts.findIndex((el) => el.id === contactId)
      if (index !== -1) {
        state.contacts[index].open = 1
        state.contacts.splice(0, 0, state.contacts.splice(index, 1)[0])
      }
    }
  },
  setContactMentionAsRead: (state, contactId) => {
    const index = state.contacts.findIndex((el) => el.id === contactId)
    if (index !== -1) {
      state.contacts[index].open = 0
    }
  },
  removeContact: (state, contact) => {
    const index = state.contacts.findIndex(contactResp => contactResp.id === contact.id)
    if (index !== -1) {
      state.contacts.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
