import axios from 'axios'

const state = {}
const getters = {}
const actions = {
  async checkout ({ rootState }, data) {
    const response = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/checkout`, {
      params: data,
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return response.data
  }
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
