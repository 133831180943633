import axios from 'axios'

const state = {}
const getters = {}
const actions = {
  async loginWithGoogle ({ commit, dispatch, rootState }, data) {
    const response = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/login/google`, data)

    if (!response.data) {
      return null
    }

    let jsonTokens = {}

    const userData = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/user`, {
      headers: {
        token: response.data.token
      }
    })
    const browserTokens = JSON.parse(localStorage.getItem('accessTokens'))
    if (browserTokens) {
      jsonTokens = browserTokens
    }
    jsonTokens[response.data.token] = {
      id: userData.data.id,
      email: userData.data.email,
      name: userData.data.name,
      date: new Date()
    }
    delete jsonTokens.null
    localStorage.setItem('accessTokens', JSON.stringify(jsonTokens))

    sessionStorage.removeItem('accessTokensSession')
    sessionStorage.setItem('accessTokensSession', response.data.token)

    await commit('setUserIsAutenticate', response.data.token)

    return response.data
  }
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
