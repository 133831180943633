<template>
  <div class="overlay" v-if="mustShow()">
    <div class="modal">
      <div class="wrapper step1">
        <div class="left ">
          <img style="position: relative; width: 100%;" v-if="this.partner" :src="`https://s3.amazonaws.com/wa-avatars.manycontacts.com/partners/${this.partner}/partner_cta.png`" onerror="this.style.display = 'none'">
          <img class="meta-business-partner" src="../../../assets/meta-business-partner-badge-dark-1.webp" />
        </div>
        <div class="right">
          <div>
            <h3 class="title">{{ $t('pages.register.subtitle') }}</h3>

            <div class="form-group">
              <label for="email">{{ $t('pages.register.email') }}</label>
              <input type="email" id="email" name="name" v-model="email" placeholder="ramon@tuempresa.com">
            </div>

            <button @click="submit($event)" :disabled="!this.email" class="button">
              {{ $t('pages.register.submit') }}
            </button>

            <div style="margin-bottom: 20px" v-html="$t('pages.register.do_login')"></div>
            <hr style="margin: 30px 0">

            <div id="googleLoginBtn" />

            <div class="language-selector">
              <select v-model="locale" @change="onChangeLocale">
                <option value="es">🇪🇸 ES</option>
                <option value="en">🇬🇧 EN</option>
                <option value="it">🇮🇹 IT</option>
                <option value="pt">🇵🇹🇧🇷 PT</option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
import store from '@/store'

export default {
  name: 'RegisterModal',
  data () {
    return {
      name: null,
      email: null,
      password: null,
      companyName: null,
      locale: null,
      numberOfUsers: null,
      phone: null,
      loading: false,
      windowWidth: window.innerWidth,
      mobileStep2: false,
      modalOpen: false
    }
  },
  computed: mapGetters(['qrTemp', 'partner']),
  methods: {
    ...mapActions(['register', 'getNewQR', 'fetchOrganization', 'setAuthToken', 'loadSession', 'loginWithGoogle', 'loginExists']),
    mustShow () {
      if (this.modalOpen && ['Register', 'RegisterSector', 'RegisterReferral'].includes(this.$route.name)) {
        return true
      }

      return false
    },
    async onRegisterSuccess (response, self) {
      if (response.promotekit && window.promotekit) {
        window.promotekit.refer(response.promotekit.email, response.promotekit.id)
      }

      await self.setAuthToken(null)
      sessionStorage.removeItem('accessTokensSession')
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

      await self.setAuthToken(response.token)
      sessionStorage.setItem('accessTokensSession', response.token)

      await self.loadSession(response.token)

      if (this.windowWidth <= 600) { // mobile
        this.mobileStep2 = true
        return
      }

      let stringConection = ''
      if (process.env.VUE_APP_WSHOST === 'localhost:3000') {
        stringConection = `ws://${process.env.VUE_APP_WSHOST}`
      } else {
        stringConection = `wss://${process.env.VUE_APP_WSHOST}`
      }

      const SocketInstance = new VueSocketIO({
        debug: true,
        connection: SocketIO(stringConection, {
          transports: ['websocket'],
          forceNew: true,
          query: `token=${response.token}`
        }),
        vuex: {
          store,
          actionPrefix: 'SOCKET_',
          mutationPrefix: 'SOCKET_'
        }
      })

      self.$.appContext.app.use(SocketInstance)

      self.fetchOrganization().then(() => {
        self.$router.push({ name: 'ConnectorSelect' })
      })
    },
    submit (event) {
      this.loading = true
      event.preventDefault()

      let partner = this.partner // FIXME check if partner is working and image is being displayed
      if (!partner && this.$route.query.via) {
        partner = this.$route.query.via
        window.promotekit_referral = partner
      }

      this.loginExists({
        email: this.email
      }).then((response) => {
        if (response.exists) {
          this.$router.push({ name: 'Login', query: { email: this.email } })
        } else {
          this.$router.push({ name: 'NewAccount', query: { email: this.email } })
        }
      }).catch((err) => {
        console.log(err)
        this.loading = false

        this.$toast.open({
          message: this.$t('pages.register.error.generic'),
          type: 'error'
        })
      })
    },
    onChangeLocale () {
      window.fetchTranslationsFor(this.locale, this.setLocale(this))
    },
    setLocale () {
      Cookies.remove('ma-locale')
      Cookies.set('ma-locale', this.locale)
      this.$i18n.locale = this.locale
    },
    open () {
      this.modalOpen = true
      setTimeout(() => {
        const emailEl = document.getElementById('email')
        if (emailEl) {
          emailEl.focus()
        }
      }, 500)
    },
    async handleCredentialResponse (credentials) {
      this.loading = true

      this.loginWithGoogle(credentials).then((response) => {
        if (!response.new) {
          window.location.href = process.env.VUE_APP_APP_HOST + '/'
          return
        }

        this.onRegisterSuccess(response, this)
      }).catch(() => {
        this.loading = false

        this.$toast.open({
          message: this.$t('pages.register.error.generic'),
          type: 'error'
        })
      })
    }
  },
  mounted () {
    const localeCookie = Cookies.get('ma-locale')
    if (localeCookie) {
      this.locale = localeCookie
    }

    if (!this.locale) {
      if (navigator.languages !== undefined) {
        this.locale = navigator.languages[0]
      }

      if (!this.locale) {
        this.locale = navigator.language
      }

      if (this.locale) {
        this.locale = this.locale.substr(0, 2)
      }
    }

    if (!this.locale) {
      this.locale = 'es'
    }

    if (this.$route.query.hsp) {
      this.phone = atob(this.$route.query.hsp)
    }

    const locale = this.locale
    if (!window.google || !window.google.accounts) {
      const script = document.createElement('script')
      script.src = `https://accounts.google.com/gsi/client?hl=${locale}`
      script.async = true

      script.addEventListener('load', () => {
        setTimeout(() => {
          const gClientId = '527323174865-9s3di1aesleeboidid3sj0tgbug3b7in.apps.googleusercontent.com'
          window.google.accounts.id.initialize({
            client_id: gClientId,
            callback: this.handleCredentialResponse,
            auto_select: true
          })

          const googleLoginBtnEl = document.getElementById('googleLoginBtn')
          if (!googleLoginBtnEl) {
            return
          }

          window.google.accounts.id.renderButton(
            googleLoginBtnEl, {
              type: 'standard',
              text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
              size: 'large', // or 'small' | 'medium'
              shape: 'rectangular', // or 'rectangular' | 'pill
              theme: 'outline', // or 'filled_black' |  'filled_blue'
              logo_alignment: 'left', // or 'center'
              locale: locale
            }
          )
        }, 500)
      })

      document.body.appendChild(script)
    }
  },
  created () {
    this.emitter.on('openRegisterPopup', this.open)
  },
  unmounted () {
    this.emitter.off('openRegisterPopup')
  }
}
</script>

<style scoped>

.overlay {
  background-color: rgb(17, 27, 33, 0.3) !important;
  opacity: 1 !important;
  z-index: 1000000 !important;
}

.modal {
  height: 490px;
  margin-left: -415px;
  left: 50%;
  top: 175px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: fit-content;
  padding: 0px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  border-radius: 10px;
}

.mobile .modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.mobile .modal .left {
  display: none;
}

.mobile .modal .right {
  width: 100%;
}

.step1 .right {
  text-align: center;
}

.step1 .right .title {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
}

h3 {
  font-size: 24px;
  color: #25D366;
}

.left {
  width: 450px;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.step1 .left {
  background-image: url("https://cdn.manycontacts.com/assets/home/main_cover_en.png");
}

.step1 .left.es {
  background-image: url("https://cdn.manycontacts.com/assets/home/main_cover_es.png");
}

.step2 .left {
  background-image: url("../../../assets/perro2.jpeg");
  background-position: right top;
}

.right {
  width: 370px;
  padding: 15px;
  position: relative;
  height: calc(100% - 30px);
}

.button {
  width: calc(100% - 30px);
  background-color: #25D366;
}

.button.button-light {
  color: #25D366;
  background-color: transparent;
}

.button:disabled {
  background-color: #25d36682;
}

.step1 .right .button {
  position: relative;
  width: 100%;
  bottom: unset;
  margin-bottom: 20px;
}

.wrapper {
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  display: flex;
  overflow: hidden;
}

.language-selector {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.step2-mobile div {
  font-size: 20px;
  margin: 20px 0;
}

</style>

<style>
.meta-business-partner {
  position: absolute;
  bottom: 12px;
  left: 2px;
  width: 170px;
}
</style>
