<template>
  <div>
    <Alerts />
    <Navbar v-if="loggedUser" />
    <NavbarFake v-if="!loggedUser" />
    <div>
      <Sidebar v-if="loggedUser"/>
      <SidebarFake v-if="!loggedUser"/>
      <Content />
    </div>
  </div>
  <MobileSidebar v-if="loggedUser"/>
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions, mapGetters } from 'vuex'

import Navbar from '../components/home/Navbar'
import NewMessage from '../components/modals/NewMessage'
import MobileSidebar from '../components/mobile/Sidebar'
import Content from '@/components/home/modules/Content'
import Sidebar from '@/components/home/Sidebar'
import NavbarFake from '@/components/home/register/NavbarFake'
import SidebarFake from '@/components/home/register/SidebarFake'

import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'HomeFeature',
  methods: {
    ...mapActions(['toggleNewUserInvite'])
  },
  props: {
    locale: String,
    feature: String
  },
  title () {
    return 'Home'
  },
  computed: mapGetters(['newMessageOpen', 'loggedUser']),
  created () {
    let selectedLocale = this.locale

    const cookieLocale = Cookies.get('ma-locale')
    if (!selectedLocale && cookieLocale) {
      selectedLocale = cookieLocale
    }

    if (!selectedLocale &&
      window.navigator &&
      window.navigator.language) {
      const navigatorLocale = window.navigator.language.substr(0, 2)
      if (['es', 'en', 'it', 'pt'].includes(navigatorLocale)) {
        selectedLocale = navigatorLocale
      }
    }

    if (!selectedLocale) {
      selectedLocale = 'es'
    }

    this.$i18n.locale = selectedLocale
    Cookies.set('ma-locale', selectedLocale)

    let moduleName = null
    if (this.$router.currentRoute.value &&
      this.$router.currentRoute.value.params &&
      this.$router.currentRoute.value.params.feature) {
      moduleName = this.$router.currentRoute.value.params.feature

      switch (this.$router.currentRoute.value.params.feature) {
        case 'calendario': moduleName = 'calendar'; break
        case 'embudo-de-ventas': moduleName = 'pipeline'; break
        case 'multi-user-crm': moduleName = 'multi-user'; break
        case 'crm-multi-usuario': moduleName = 'multi-user'; break
        case 'api': moduleName = 'api'; break
        case 'envios-masivos-whatsapp': moduleName = 'campaigns'; break
        case 'campos-personalizados': moduleName = 'custom-fields'; break
        case 'buzon-email': moduleName = 'emailbox'; break
        case 'importar-contactos-excel': moduleName = 'import-excel'; break
        case 'importar-contactos-whatsapp-business': moduleName = 'import-qr'; break
        case 'llamadas-entrantes': moduleName = 'inbound-call-wa'; break
        case 'chat-interno': moduleName = 'internal-chat'; break
        case 'enlaces-whatsapp': moduleName = 'link-qr'; break
        case 'whatsapp-widget-web': moduleName = 'widget'; break
        case 'llamadas-salientes': moduleName = 'zadarma'; break
        case 'programar-envios-whatsapp': moduleName = 'scheduled-sent'; break
      }
    }

    if (moduleName) {
      if (this.$route.name === 'IntegrationRedirect' ||
          this.$route.name === 'IntegrationRedirectLocale') {
        window.emitter.emit('openModuleIntegrationPopup', moduleName)
        return
      }

      window.emitter.emit('openModulePopup', moduleName)
    }
  },
  components: {
    Alerts,
    SidebarFake,
    NavbarFake,
    Sidebar,
    Content,
    Navbar,
    NewMessage,
    MobileSidebar
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.anyAlert .content {
  margin-top: 127px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
