<template>
  <div v-if="this.modalOpen" class="overlay" @click="closeAlerts">
    <div class="modal slide">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <h3>{{ $t('alerts.title') }}</h3>
      <div class="fileslist">
        <div :class="{ unread: alert.pending === null }" class="alert" v-for="alert in alerts" :key="alert.id">
          <div v-html="getText(alert)"></div>
          <div @click="markAsRead(alert.id)" class="read" v-tooltip.left="{ content: $t('alerts.read') }"><i v-if="alert.pending === null" class="fas fa-eye"></i></div>
          <div class="alert-date">{{ $moment(alert.createdAt).format('DD MMM YYYY') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Announcements',
  computed: mapGetters(['alerts']),
  data () {
    return {
      modalOpen: false,
      locale: 'es'
    }
  },
  methods: {
    ...mapActions(['fetchAlerts', 'readAlert']),
    close () {
      this.modalOpen = false
    },
    closeAlerts (event) {
      const containerWidth = window.innerWidth
      const leftAreaContainer = containerWidth - 440
      if (event.clientX <= leftAreaContainer) {
        this.modalOpen = false
      }
    },
    open () {
      const localeCookie = Cookies.get('ma-locale')
      if (localeCookie) {
        this.locale = localeCookie
      }

      if (!this.locale) {
        if (navigator.languages !== undefined) {
          this.locale = navigator.languages[0]
        }

        if (!this.locale) {
          this.locale = navigator.language
        }

        if (this.locale) {
          this.locale = this.locale.substr(0, 2)
        }
      }

      if (!this.locale) {
        this.locale = 'es'
      }

      this.modalOpen = true
      this.fetchAlerts()
    },
    markAsRead (alertId) {
      this.readAlert(alertId)
    },
    getText (alert) {
      let text = alert.text
      if (this.locale !== 'es' && alert.text_en) {
        text = alert.text_en
      }

      return text
    }
  },
  created () {
    this.emitter.on('openAnnouncements', this.open)
  },
  unmounted () {
    this.emitter.off('openAnnouncements')
  }
}
</script>

<style scoped>
.slide {
  top: 0;
  bottom: 0;
  height: unset;
  right: 0;
  width: 400px;
  margin-left: unset;
  left: unset;
}

.overlay {
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.alert {
  background-color: #F1F3F4;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  position: relative;
}

.alert.unread {
  background-color: #4285f45e;
}

.alert .alert-date {
  text-align: right;
  width: 100%;
}

.alert .read {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}

.fileslist {
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>

<style>
.alert img {
  text-align: center;
}
</style>
