<template>
  <div>
    <Alerts />
    <Navbar />
    <div>
      <Sidebar />
      <Content />
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Navbar from '../components/home/Navbar'
import NewMessage from '../components/modals/NewMessage'
import MobileSidebar from '../components/mobile/Sidebar'
import Content from '@/components/home/pipelines/Content'
import Sidebar from '@/components/home/Sidebar'

import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'Pipelines',
  methods: {
    ...mapActions(['toggleNewUserInvite'])
  },
  props: {
    filterView: Number,
    page: Number
  },
  computed: mapGetters(['newMessageOpen', 'loggedUser']),
  components: {
    Alerts,
    Sidebar,
    Content,
    Navbar,
    NewMessage,
    MobileSidebar
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.anyAlert .content {
  margin-top: 127px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
