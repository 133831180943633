<template>
  <nav @click="openRegister($event)">
    <div class="logo-wrapper" >
      <div class="personal-logo">
        <div>
          <img v-if="!this.partner" src="../../../assets/manycontacts-logo.svg" class="logo-default"/>
          <img v-if="this.partner" :src="`https://s3.amazonaws.com/wa-avatars.manycontacts.com/partners/${this.partner}/logo.png`" class="logo" onerror="this.style.display = 'none'"/>
        </div>
        <div v-if="this.partner">
          <div class="organization-name">
            {{ getTitleCase(this.partner) }}
          </div>
          <div class="by-manycontacs">
            Powered by <img src="../../../assets/manycontacts-logo.svg" class="logo-default-mini"/>
          </div>
        </div>
      </div>
      <div class="personal-logo-responsive">
        <img src="../../../assets/manycontacts-isotype.svg" class="logo-default"/>
      </div>
    </div>

    <div class="new-chat" :data-hint="$t('wizard.hints.new_chat')" data-hint-position="bottom-middle">
      <i class="fas fa-plus"></i>
      {{ $t('sidebar.chat_new') }}
    </div>

    <div class="options">
      <a :href="getLoginUrl()">
        <button class="button button-login">{{ $t('navbar.login') }}</button>
      </a>
      <router-link :to="{ name: 'Register' }">
        <button class="button button-register">{{ $t('modules.create_account') }}</button>
      </router-link>
      <div v-if="mustShow()" class="language-selector">
        <select v-model="locale" @change="onChangeLocale" class="language-selector-select">
          <option value="es">🇪🇸 ES</option>
          <option value="en">🇬🇧 EN</option>
          <option value="it">🇮🇹 IT</option>
          <option value="pt">🇵🇹🇧🇷 PT</option>
        </select>
      </div>
    </div>
    <div class="options-responsive">
      <a :href="getLoginUrl()">
        <button class="button button-login">Login</button>
      </a>
      <router-link :to="{ name: 'Register' }">
        <button class="button button-register">{{ $t('modules.create_account_responsive') }}</button>
      </router-link>
    </div>

  </nav>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export default {
  name: 'NavbarFake',
  data () {
    return {
      locale: null
    }
  },
  computed: mapGetters(['partner']),
  methods: {
    mustShow () {
      return this.$route.path !== '/register'
    },
    onChangeLocale () {
      window.fetchTranslationsFor(this.locale, this.setLocale(this))
    },
    setLocale () {
      this.$i18n.locale = this.locale

      Cookies.remove('ma-locale')
      Cookies.set('ma-locale', this.locale)

      const routeParams = this.$router.currentRoute.value.params
      routeParams.locale = this.locale

      const url = this.$router.resolve({ name: this.$router.currentRoute.value.name, params: routeParams })
      window.history.pushState({}, null, url.href)
    },
    getTitleCase (str) {
      const splitStr = str.toLowerCase().replaceAll('-', ' ').split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    },
    getLoginUrl () {
      return process.env.VUE_APP_WEB_HOST_LOGIN
    },
    openRegister (event) {
      if (event &&
        event.target &&
        event.target.classList) {
        if (event.target.classList.contains('language-selector-select')) {
          return
        }
        if (event.target.classList.contains('button-login')) {
          return
        }
        if (event.target.classList.contains('button-register')) {
          event.preventDefault()
          event.stopPropagation()
        }
      }

      if (this.partner) {
        this.$router.push(`/register/${this.partner}`)
        return
      }

      this.$router.push('/register')
    }
  },
  mounted () {
    const localeCookie = Cookies.get('ma-locale')
    if (localeCookie) {
      this.locale = localeCookie
    }

    if (!this.locale) {
      if (navigator.languages !== undefined) {
        this.locale = navigator.languages[0]
      }

      if (!this.locale) {
        this.locale = navigator.language
      }

      if (this.locale) {
        this.locale = this.locale.substr(0, 2)
      }
    }

    if (!this.locale) {
      this.locale = 'es'
    }
  }
}
</script>

<style scoped>
.language-selector {
  margin-right: 30px;
}

.button-login {
  position: relative;
  padding: 0 20px;
  width: fit-content;
  bottom: unset;
}

.button-register {
  position: relative;
  right: 15px;
  padding: 0 20px;
  width: fit-content;
  background-color: #25D366;
  bottom: unset;
}

nav {
  height: 34px;
  position: fixed;
  width: calc(100% - 32px);
  top: 0;
  border-bottom: 1px solid #EDEFF1;
  z-index: 3;
  padding: 16px 16px;
  display: flex;
}

.anyAlert nav {
  top: 60px;
}

.expand {
  font-size: 16px;
  padding: 7px 12px;
  margin: 0 17px 0 0;
  border-radius: 100%;
  background-color: rgb(245, 245, 245);
  color: #5299DC;
  cursor: pointer;
  height: 22px;
}

.expand:hover {
  background-color: #4285f45e;
  color: #4285f4;
}

.options, .options-responsive {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
  flex-direction: row-reverse;
}

.logo {
  height: 35px;
}

ul {
  flex-direction: row;
  display: flex;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

Switch {
  margin-right: 20px;
}

.mobile .burger, .mobile .coins, .mobile .alerts, .mobile .new-chat {
  display: none;
}

.mobile .logo {
  display: none;
}

.mobile .isotype {
  display: block;
}

.options-responsive {
  display: none !important;
}

.mobile .options-responsive {
  display: flex !important;
}

.mobile .options {
  display: none !important;
}

.new-chat {
  border-radius: 24px;
  color: #5299DC;
  height: 36px;
  margin: 0px 0 0px 20px;
  box-shadow: 0 0px 0px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 16px;
  align-items: center;
  background-color: #fff;
  background-image: none;
  display: inline-flex;
  font-weight: 500;
  overflow: hidden;
  padding: 0 16px;
  cursor: pointer;
}

.new-chat:hover {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.30), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  background-color: #fafafb;
}

.new-chat i {
  margin-right: 10px;
}

.logo-wrapper {
  position: relative;
  min-width: 185px;
  max-width: 300px;
}

.logo {
  padding: 0;
  margin: 0;
  border-radius: 35px;
  width: 35px;
  height: 35px;
}

.personal-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  flex-direction: row;
}

.personal-logo-responsive {
  display: none !important;
}

.mobile .personal-logo-responsive {
  display: flex !important;
  min-width: unset !important;
  max-width: 40px !important;
}

.mobile .personal-logo {
  display: none !important;
}

.organization-name {
  text-decoration: none;
  padding: 0 0 0 15px;
  color: #111b21;
  font-weight: 700;
  font-size: 19px;
  max-width: 252px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.by-manycontacs {
  padding: 0 0 0 15px;
  color: #5299DC;
  font-size: 8px;
  line-height: 15px;
  position: relative;
}

.logo-default-mini {
  height: 15px;
  position: absolute;
  top: 0;
  margin-left: 2px;
}

.logo-default {
  height: 35px;
}
</style>
