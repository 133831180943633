<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3>{{ $t('messages.new.title') }}</h3>

    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div v-if="newMessageForm === 'whatsapp'" class="whatsapp">
      <div v-if="canStartNewConversations()">
        <p>{{ $t('messages.new.subtitle') }}</p>

        <div class="form-group">
          <input type="tel" id="tel-input" class="tel-input" v-model="phone" @input="onInputPhone" />
        </div>

        <div v-if="!newContact" class="spam-warning">
          {{ $t('messages.new.spam') }}
        </div>

        <div v-if="newContact" class="accept-contact">
          <input type="checkbox">
          <div>{{ $t('messages.new.consent') }}</div>
        </div>
        <button :disabled="!phoneValid || busy" class="button" @click="onClickSubmit">{{ $t('messages.new.submit') }}</button>
      </div>
      <div v-if="!canStartNewConversations()">
        <p v-if="this.organization.watype === null" v-html="$t('onboarding.blocked_chat')"></p>
        <p v-if="this.organization.watype === 'api'" class="accept-contact" v-html="$t('onboarding.not_approved.subtitle')"></p>
      </div>
    </div>

    <div v-if="newMessageForm === 'internal'" class="internal">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

      <div v-if="organizationUsers && hasHumanUsers(organizationUsers)" class="form-group user-list">
        <p style="margin-top: 0">{{ $t('internal.modal.subtitle') }}</p>

        <div :hidden="user.ai" class="user-item" @click="onClickInternalUser(user)" v-for="user in internalChatUsers()" :key="user.id" >
          <div class="name">{{ user.name }}</div>
          <div class="email">{{ user.email }}</div>
          <div v-if="user.isActive === true" class="active">{{ $t('navbar.status.active') }}</div>
          <div v-if="user.isActive === false" class="ooo">{{ $t('navbar.status.ooo') }}</div>
        </div>
      </div>
      <div v-if="!organizationUsers || !hasHumanUsers(organizationUsers)">
        <p style="margin-top: 0" v-html="$t('internal.modal.no_partners')"></p>

        <button class="button button-invite" type="submit" @click="this.$router.push('/settings/permissions'); this.toggleNewMessage()">{{ $t('internal.modal.no_partners_invite') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MessageNew',
  data () {
    return {
      newContact: false,
      contact: null,
      phone: '+34',
      phoneValid: false,
      busy: false
    }
  },

  computed: mapGetters(['organizationUsers', 'loggedUser', 'organization', 'filter', 'newMessageForm']),
  methods: {
    ...mapActions(['findContactByPhone', 'startConversation', 'toggleNewMessage', 'setNewMessageForm', 'startConversationInternal', 'setPage', 'setFilterView', 'fetchContacts']),
    onInputPhone: function () {
      this.phone = this.phone.replaceAll(' ', '')

      if (this.phone === '') {
        this.phoneValid = false
        this.newContact = false
        return
      }

      const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
      const count = (this.phone.match(/\+/g) || []).length
      if (count > 1) {
        if (this.phone.substr(-1) === '+') {
          this.phone = this.phone.substr(0, this.phone.length - 1)
        } else {
          const indexes = [...this.phone.matchAll(/\+/gi)].map(a => a.index)
          this.phone = this.phone.substr(indexes[1])
        }
      }

      if (count === 0) {
        if (regex.test('+' + this.phone)) {
          this.phone = '+' + this.phone
        } else {
          this.phone = '+34' + this.phone
        }
      }

      if (regex.test(this.phone)) {
        this.findContactByPhone(this.phone).then((contact) => {
          this.contact = contact
          if (this.contact === null) {
            this.newContact = true
          } else {
            this.newContact = false
          }

          this.phoneValid = true
        }).catch(() => {
          this.phoneValid = false
          this.newContact = false
          this.contact = null
        })
      }
    },
    hasHumanUsers (users) {
      for (const user of users) {
        if (!user.ai && this.loggedUser.id !== user.id) {
          return true
        }
      }

      return false
    },
    onClickSubmit () {
      this.busy = true
      this.startConversation(this.phone.replaceAll('+', '')).then(() => {
        this.busy = false
      }).catch(() => {
        this.busy = false
      })
    },
    close () {
      this.phone = '+34'

      this.setNewMessageForm('whatsapp')
      this.toggleNewMessage()
    },
    internalChatUsers () {
      const usersAvailable = []

      for (const user of this.organizationUsers) {
        if (user.id !== this.loggedUser.id) {
          usersAvailable.push(user)
        }
      }

      return usersAvailable
    },
    onClickInternalUser (user) {
      this.$router.push({ name: 'Internal' }).then(() => {
        this.setPage(0)
        this.fetchContacts({ filter: 7 }).then(() => {
          this.startConversationInternal(user)
        })
      })
    },
    canStartNewConversations () {
      if (this.organization && this.organization.watype === null) {
        return false
      }

      if (this.organization && this.organization.watype === 'api' && this.organization.hasApiKey && !this.organization.emptyTemplate) {
        return false
      }

      return true
    }
  }
}
</script>

<style>
.overlay {
  z-index: 3000001;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 3000002;
  background-color: #ffffff;
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  height: 250px;
  padding: 20px;

  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

input.tel-input  {
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: .2px;
}

.accept-contact {
  padding: 10px;
  margin-top: 20px;
  background-color: #fff1e2;
  border-color: #fce2ba;
}

.accept-contact input {
  float: left;
}

.accept-contact > div {
  padding-left: 30px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  text-align: center;
  bottom: 20px;
  position: absolute;
  width: calc(100% - 40px);
  cursor: pointer;
  border-radius: 4px;
  border: 0;
}

.button.button-free {
  position: relative;
  bottom: unset;
}

.button.button-small {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-weight: 400;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}
</style>

<style scoped>

.modal {
  height: 400px;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs .tab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #EDEFF1;
  padding: 8px;
  cursor: pointer;
}

.tabs .tab.active {
  font-weight: 500;
  border-top: 1px solid #EDEFF1;
  border-left: 1px solid #EDEFF1;
  border-right: 1px solid #EDEFF1;
  border-bottom: 0;

  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.spam-warning {
  margin-top: 20px;
  padding: 10px;
  background-color: #4285f45e;
  border-radius: 4px;
}

.user-list {
  height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.user-item {
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.user-item .active,
.user-item .ooo {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 10px;
}

.user-item .name {
  font-weight: 400;
}

.user-item .email {
  font-size: 10px;
}

.user-item .active {
  background-color: #7bed9f;
}

.user-item .ooo {
  background-color: #d2d2d2;
}

.button-invite {
  position: relative;
  width: 100%;
  margin-top: 30px;
}
</style>
