<template>
  <li @click="loadConversation">
    <div class="message-name">
      {{ message.contact.name }} (+{{message.contact.number}})
    </div>
    <div class="message-phonenumber" v-html="highlight(message.text, this.searchString)"></div>
    <div class="message-time">
      <div v-if="new Date(message.timestamp) > this.today">{{ $moment(message.timestamp).format('HH:mm') }}</div>
      <div v-else-if="new Date(message.timestamp) > this.newYear">{{ $moment(message.timestamp).format('DD MMM') }}</div>
      <div v-else>{{ $moment(message.timestamp).format('L') }}</div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListItemMessage',
  computed: mapGetters(['searchString']),
  methods: {
    ...mapActions(['fetchConversation', 'clearSearch']),
    highlight (text, search) {
      const wrapAround = 30
      const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      const normalizedSearch = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      const regex = new RegExp(`\\b(${normalizedSearch})\\b`, 'i')

      const match = normalizedText.match(regex)
      if (!match) {
        return text
      }

      const startPos = normalizedText.indexOf(match[0])
      const endPos = startPos + match[0].length

      const snippetStart = Math.max(0, startPos - wrapAround)
      const snippetEnd = Math.min(text.length, endPos + wrapAround)
      const snippet = text.slice(snippetStart, snippetEnd)

      return snippet.replace(regex, '<mark>$1</mark>')
    },
    loadConversation () {
      if (location.pathname.includes('chats') ||
        location.pathname.includes('conversation')) {
        this.fetchConversation({ contact: this.message.contact, messageId: this.message.id }).then(() => {
          this.clearSearch()
          this.$emit('clearSearch')
        })
      } else {
        this.emitter.emit('openContactOverlay', this.message.contact.id)
      }
    }
  },
  props: {
    message: Object
  },
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      today: today,
      newYear: newYear
    }
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 40px;
  color: #111b21;
  padding: 10px 15px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

li:hover {
  background: rgba(242, 245, 245, 0.8);
  /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);*/
  z-index: 2;
}

li .message-name {
  position: absolute;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

li .message-phonenumber {
  position: absolute;
  top: 29px;
  width: 270px;
  color: #667781;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li .message-time {
  position: absolute;
  right: 15px;
  color: #667781;
}
</style>
