<template>
  <div v-if="modalOpen" class="overlay">
    <div v-if="module" class="modal module">
      <div class="header">
        <div class="icon">
          <div :class="module.key" class="icon-wrapper">
            <i v-if="module.key==='calendar'" class="far fa-calendar"></i>
            <i v-if="module.key==='pipeline'" class="fas fa-filter"></i>
            <i v-if="module.key==='multi-user'" class="fas fa-users"></i>
            <i v-if="module.key==='api'" class="fa-solid fa-code"></i>
            <i v-if="module.key==='campaigns'" class="fas fa-bullhorn"></i>
            <i v-if="module.key==='chatbot'" class="fas fa-robot"></i>
            <i v-if="module.key==='custom-fields'" class="fas fa-stream"></i>
            <i v-if="module.key==='emailbox'" class="fas fa-envelope"></i>
            <i v-if="module.key==='import-excel'" class="fas fa-file-excel"></i>
            <i v-if="module.key==='import-qr'" class="fas fa-qrcode"></i>
            <i v-if="module.key==='inbound-call-wa'" class="fas fa-phone"></i>
            <i v-if="module.key==='internal-chat'" class="fas fa-th-large"></i>
            <i v-if="module.key==='klaviyo'"> <img style="width: 50px;" src="../../assets/klaviyo_blue.svg" /></i>
            <i v-if="module.key==='link-qr'" class="fas fa-link"></i>
            <i v-if="module.key==='pabbly-connect'" class="fas fa-project-diagram"></i>
            <i v-if="module.key==='widget'" class="fas fa-circle"></i>
            <i v-if="module.key==='zadarma'" class="fas fa-phone-volume"></i>
            <i v-if="module.key==='zapier'" class="fas fa-puzzle-piece"></i>
            <i v-if="module.key==='scheduled-sent'"> <img style="width: 50px; margin-top: 16px;" src="../../assets/send-scheduled_blue.svg" /></i>
            <i v-if="module.key==='premium-support'" class="fas fa-headset"></i>
            <i v-if="module.key==='whatsapp-expert'" class="fas fa-toolbox"></i>
            <i v-if="module.key==='ai'" class="fas fa-robot"></i>
          </div>
        </div>

        <h1>{{ $t(`modules.${module.key}.title`) }}</h1>
        <p class="short_description">{{ $t(`modules.${module.key}.subtitle`) }}</p>
        <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      </div>
      <div class="body">
        <div class="left">
          <div v-html="this.body"></div>
          <br>
          <h4 style="margin-bottom: 0; text-align: center">{{ $t('modules.related_modules') }}</h4>
          <div class="random-modules">
            <FeatureModule :module="module" v-for="module in this.randomModules" :key="module.id" />
          </div>
        </div>
        <div v-if="!organization && !loggedUser" class="right">
          <div>
            <div class="price">
              <button @click="openRegister">{{ $t('modules.create_account') }}</button>
            </div>
          </div>
          <br>
          <h4>{{ $t('modules.tags.title') }}</h4>
          <div v-if="module.tags.length > 0" class="tags">
            <div class="tag" v-for="tag in module.tags" :key="tag.id">{{$t(`modules.tags.${tag.name}`)}}</div>
          </div>
        </div>
        <div v-if="organization" class="right" style="width: 20%">
          <div v-if="isModuleEnabled(module.key)" style="width: 100%; margin-bottom: 20px;">
            <router-link v-if="module.redirectUrl && !isRestricted(module)" :to="module.redirectUrl">
              <button @click="this.close()">{{$t('modules.setup')}}</button>
              <br><br>
            </router-link>
            <button class="uninstall" @click="onClickDisable">{{ $t('modules.activateable.disable') }}</button>
          </div>
          <div v-if="module.activateable === 1">
            <div class="price">
              <button @click="onClickEnable" v-if="!isModuleEnabled(module.key) && !isRestricted(module)">{{ $t('modules.activateable.activate') }}</button>
              <button @click="onClickEnable" v-if="!isModuleEnabled(module.key) && isRestricted(module)">
                {{ $t('modules.restricted.info') }}
              </button>
              <div v-if="module.price === 0" style="margin: 10px 0;">{{ $t('modules.included') }}</div>
              <div v-if="module.price > 0" style="margin: 10px 0;">
                {{ module.price }}€<span v-if="module.pricePerUser">/{{ $t('modules.agent') }}</span><span v-if="module.monthly">/{{ $t('modules.month') }}</span>
              </div>
              <span v-if="module.active" class="active"><i class="fas fa-check-circle"></i> {{ $t('modules.activateable.active') }}</span>
            </div>
          </div>
          <div class="price" v-if="module.activateable === 0">
            <router-link v-if="module.redirectUrl && !isRestricted(module)" :to="module.redirectUrl">
              <button @click="this.close()">{{ $t('modules.activateable.go') }}</button>
            </router-link>
            <button @click="onClickEnable" v-if="module.price > 0 && !isModuleEnabled(module.key) && !isRestricted(module)">{{ $t('modules.activateable.activate') }}</button>
            <button @click="onClickEnable" v-if="isRestricted(module)">
              {{ $t('modules.restricted.info') }}
            </button>
            <div v-if="module.price === 0" style="margin: 10px 0;">{{ $t('modules.included') }}</div>
            <div v-if="module.price > 0" style="margin: 10px 0;">
              {{ module.price }}€<span v-if="module.pricePerUser">/{{ $t('modules.agent') }}</span><span v-if="module.monthly">/{{ $t('modules.month') }}</span>
            </div>
          </div>
          <br>
          <h4>{{ $t('modules.tags.title') }}</h4>
          <div v-if="module.tags.length > 0" class="tags">
            <div class="tag" v-for="tag in module.tags" :key="tag.id">{{$t(`modules.tags.${tag.name}`)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import axios from 'axios'
import FeatureModule from '@/components/home/modules/FeatureModule'

export default {
  name: 'Module',
  components: { FeatureModule },
  data () {
    return {
      modalOpen: false,
      module: null,
      redirectUrl: null,
      body: null,
      randomModules: [],
      payUrl: null
    }
  },
  computed: mapGetters(['modules', 'organizationModules', 'organization', 'loggedUser']),
  methods: {
    ...mapActions([
      'enableOrganizationModule',
      'disableOrganizationModule',
      'getSupportContact',
      'fetchContacts',
      'fetchConversation',
      'fetchModulesRandom',
      'fetchModuleByKey',
      'fetchCheckoutSessionLinkForModule'
    ]),
    openRegister () {
      this.close()
      this.$router.push('/register')
    },
    close () {
      this.modalOpen = false
      if (!this.organization) {
        const emailEl = document.getElementById('email')
        if (emailEl) {
          emailEl.focus()
        }
      }
    },
    async open (module, redirectUrl) {
      if (!module) {
        return
      }

      if (typeof module === 'string') {
        if (this.modules) {
          this.module = this.modules.find(m => m.key === module)
        }

        if (!this.module) {
          this.module = await this.fetchModuleByKey(module)
        }
      } else {
        this.module = module
      }

      if (!this.module) {
        return
      }

      this.redirectUrl = redirectUrl
      this.modalOpen = true

      const key = this.module.key

      let locale = Cookies.get('ma-locale')
      if (!locale) {
        if (navigator.languages !== undefined) {
          locale = navigator.languages[0]
        }

        if (!locale) {
          locale = navigator.language
        }

        if (locale) {
          locale = locale.substr(0, 2)
        }
      }

      if (!locale) {
        locale = 'es'
      }

      if (this.module.price > 0) {
        this.fetchCheckoutSessionLinkForModule(this.module).then((paymentLink) => {
          this.payUrl = paymentLink
        })
      }

      const timestamp = (new Date()).getTime()
      axios.get(`${process.env.VUE_APP_PUBLIC_SERVERHOST}/modules_body/${locale}/${key}.html?ver=${timestamp}`)
        .then((res) => {
          this.body = res.data
        }).catch(() => {
          axios.get(`${process.env.VUE_APP_PUBLIC_SERVERHOST}/modules_body/en/${key}.html?ver=${timestamp}`)
            .then((res) => {
              this.body = res.data
            }).catch(() => {
              this.body = `Not exists modules_body/${locale}/${key}.html`
            })
        })

      this.fetchModulesRandom(this.module).then((modules) => {
        this.randomModules = modules
      })
    },
    onClickEnable () {
      if (this.module.price === 0) {
        this.enableModule(this.module)
      } else {
        window.location.href = this.payUrl
      }
    },
    enableModule (module) {
      this.enableOrganizationModule(module.key)
        .then(() => {
          if (this.isRestricted(module)) {
            this.$toast.open({
              message: this.$t('modules.restricted.ok')
            })
            this.goToSupportContact()
            this.close()
            return
          }

          if (this.redirectUrl) {
            this.$router.push(this.redirectUrl)
          } else if (this.module.redirectUrl) {
            this.$router.push(this.module.redirectUrl)
          }

          this.close()
        }).catch((err) => {
          alert(err)
        })
    },
    onClickDisable () {
      this.disableOrganizationModule(this.module.key).then(() => {
        this.close()
      }).catch((err) => {
        alert(err)
      })
    },
    isModuleEnabled (key) {
      if (this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    isRestricted (module) {
      if (module.type !== null && module.type !== this.organization.watype) {
        return true
      }

      if (module.direct === 0) {
        return true
      }

      return false
    },
    goToSupportContact () {
      this.$router.push('/chats').then(() => {
        this.getSupportContact().then(contact => {
          this.fetchContacts({ filter: 1, page: 0 }).then(() => {
            this.fetchConversation({ contact: contact, messageId: null })
          })
        })
      })
    }
  },
  created () {
    this.emitter.on('openModulePopup', this.open)
    this.emitter.on('closeModulePopup', this.close)
  },
  unmounted () {
    this.emitter.off('openModulePopup')
    this.emitter.off('closeModulePopup')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 9000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  z-index: 9000001;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 4px 14px 0 rgba(0,0,0,.2);
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  width: 90%;
  left: unset;
  right: unset;
  margin: 0 auto;
  position: relative;
}

.module {
  max-width: 1000px;
}

.modal-close {
  cursor: pointer;
}

.header {
  height: 115px;
  padding: 20px;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,.1);
  background-color: #f1f1f1;
  border-radius: 4px 4px 0 0;
}

.header h1 {
  padding: 0;
  margin: 0;
  line-height: 40px;
}

.mobile .header h1 {
  margin-top: 14px;
}

.left {
  border-right: 1px solid rgba(0,0,0,.1);
  white-space: normal;
  overflow: scroll;
  width: 80%
}

.left, .right {
  padding: 20px
}

.icon {
  border-radius: 4px;
  border: 1px solid #EDEFF1;
  float: left;
  margin-right: 15px;
  padding: 15px;
}

.icon img {
  width: 85px;
}

.icon-wrapper {
  width: 85px;
  height: 85px;
  border-radius: 6px;
  background: linear-gradient(to bottom right, #F1F8FF,#C4E0FD);
  color: #4285f4;
  font-size: 45px;
  text-align: center;
  line-height: 85px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.name {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.short_description {
  margin-top: 10px;
  font-size: 14px;
  width: calc(100% - 175px);
  margin-left: 135px;
  line-height: 20px;
}

.price {
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}

.active {
  margin-right: 10px;
  background-color: rgb(82, 204, 167,.15);
  font-weight: 400;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  color: #1a202c;
}

.active i {
  color: #7bed9f;
}

.tags {
  top: 50px;
  right: 20px;
  text-align: left;
}

.tags .tag {
  padding: 1px 7px;
  text-decoration: none;
  margin: 13px 0 0px;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  box-sizing: border-box;
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #757575;
}

.mobile .tags {
  display: flex;
}

.mobile .tags .tag {
  margin: 0px 10px 0px 0px;
}

.body {
  height: calc(100% - 160px);
  width: 100%;
  display: inline-flex;
}

.mobile .body {
  flex-direction: column-reverse;
}

.mobile .right {
  -webkit-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
}

.mobile .right h4 {
  margin-top: 0px;
}

.mobile .left {
  width: 100% !important;
  padding: 0;
}

button {
  background-color: #4285f4;
  color: #ffffff;
  width: 100%;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  border: 0;
  margin-right: 15px;
  font-weight: 600;
}

button.uninstall {
  background-color: #ff7675;
  margin-right: 0;
}

.random-modules {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 0 40px 20px;
}

.mobile .random-modules {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  margin: 40px 20px 40px 20px;
}

.mobile .random-modules .module {
  zoom: 1;
  height: 275px;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
}

.mobile .module {
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  border-radius: 0;
  position: fixed;
}

.mobile .short_description {
  display: none;
}
</style>

<style>
.modal.module .body .left p {
  padding: 10px 20px;
  line-height: 20px;
  margin: 0;
}

.mobile .module iframe {
  zoom: 0.6;
}

.random-modules .module {
  zoom: 0.85
}
</style>
