<template>
  <div>
    <Alerts />
    <Navbar />
    <div class="content">
      <Sidebar />
      <div class="stats">
        <div class="container-list-header">
            <i @click="this.selectedTab = 'general'" :class="{ active: this.selectedTab === 'general' }" class="fas fa-chart-line"><span>{{ $t('stats.general')}}</span></i>
            <i @click="this.selectedTab = 'advanced'" :class="{ active:  this.selectedTab === 'advanced' }" class="fas fa-stopwatch"><span>{{ $t('stats.response_time') }}</span></i>
        </div>
        <iframe v-if="organization && this.selectedTab === 'general'" class="iframe-stats"
                :src="this.getUrl(1)"
                frameborder="0"
                width="800"
                height="600"
                allowtransparency
        ></iframe>
        <iframe v-if="organization && this.selectedTab === 'advanced'" class="iframe-stats"
                :src="this.getUrl(6)"
                frameborder="0"
                width="800"
                height="600"
                allowtransparency
        ></iframe>
      </div>
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import jwt from 'jsonwebtoken'

import Navbar from '../components/home/Navbar'
import Sidebar from '../components/home/Sidebar'
import NewMessage from '../components/modals/NewMessage'

import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'Stats',
  computed: mapGetters(['newMessageOpen', 'organization']),
  components: {
    Alerts,
    Navbar,
    Sidebar,
    NewMessage
  },
  data () {
    return {
      selectedTab: 'general'
    }
  },
  methods: {
    ...mapActions(['setFilterView', 'setSection']),
    getUrl (dashboard) {
      const payload = {
        resource: { dashboard: dashboard },
        params: {
          organization_id: this.organization.id
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60)
      }

      const secretKey = process.env.VUE_APP_METABASE_SECRET_KEY
      const token = jwt.sign(payload, secretKey)

      return process.env.VUE_APP_METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false'
    }
  },
  created () {
    this.setFilterView(null)
    this.setSection(null)
  }
}
</script>

<style scoped>
.content {
  margin-top: 67px;
  width: calc(100% - 71px);
  margin-left: 71px;
}

.iframe-stats {
  width: calc(100vw - 71px);
  height: calc(100vh - 110px);
  border: 0;
}

.anyAlert .content {
  height: calc(100vh - 126px);
  margin-top: 127px;
}

.menu-collapse .content {
  width: calc(100% - 250px - 70px);
  margin-left: 70px;
}

.mobile .content {
  width: 100%;
  margin-left: 0px;
}

.container-list-header {
  padding: 10px 15px;
  text-align: left;
  min-height: 20px;
  border-bottom: 1px solid #EDEFF1;

}

.container-list-header img {
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 13px;
}

.container-list-header i {
  border-radius: 4px;
  cursor: pointer;
  color: #111b21;
  margin-right: 10px;
  padding: 5px 10px;
}
.container-list-header i span {
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-left: 5px;
}

.container-list-header i.active {
  background-color: #4285f45e;
  color: #4285f4;
}
</style>

<style>
.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
