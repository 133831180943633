<template>
  <div v-if="modalOpen" class="overlay" @click="closeOverlay"></div>
  <div v-if="modalOpen" class="modal">
    <h3>{{ $t('quality_rating.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    <div class="form-group scroll">
        <div v-html="$t('quality_rating.text')"></div>
    </div>
    <div>
      <button class="button" @click="clickContactUs">
        {{ $t('quality_rating.contact_us') }}

      </button>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QualityRating',
  data () {
    return {
      modalOpen: false
    }
  },
  computed: mapGetters([]),
  methods: {
    ...mapActions(['getSupportContact', 'fetchContacts', 'fetchConversation', 'clearSelectedContact']),
    close () {
      this.modalOpen = false
    },
    open () {
      this.modalOpen = true
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    clickContactUs () {
      this.$router.push('/chats').then(() => {
        this.getSupportContact().then(contact => {
          this.clearSelectedContact()
          this.setMessageCache(contact.id, this.$t('quality_rating.contact_us_message'))
          this.fetchContacts({ filter: 1, page: 0 }).then(() => {
            this.fetchConversation({ contact: contact, messageId: null }).then(() => {
              this.emitter.emit('onDiscardFile')
            })
          })
        })
      })

      this.close()
    },
    setMessageCache (contactId, text) {
      const cacheMessageKey = `message-${contactId}`
      if (text === '') {
        localStorage.removeItem(cacheMessageKey)
        return
      }
      localStorage.setItem(cacheMessageKey, text)
    }
  },
  created () {
    this.emitter.on('openQualityRating', this.open)
  },
  unmounted () {
    this.emitter.off('openQualityRating')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 3000003;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 3000004;
  background-color: #ffffff;
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -220px;
  width: 440px;
  height: 360px;
  max-height: 750px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.modal textarea {
  width: calc(100% - 30px);
}
</style>
