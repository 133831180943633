<template>
  <div class="fa-3x loading">
    <i v-if="!this.ok" class="fas fa-circle-notch fa-spin"></i>

    <div v-if="this.ok">
      Pago realizado con exito.
      <br>
      <a href="https://app.manycontacts.com/">Ir a ManyContacts</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Stripe',
  data () {
    return {
      ok: false
    }
  },
  methods: {
    ...mapActions(['saveStripePayment'])
  },
  created () {
    const sessionId = this.$route.query.session_id

    this.saveStripePayment(sessionId).then((response) => {
      this.ok = true

      setTimeout(() => {
        if (!response.data || !response.data.url || response.data.url === '' || response.data.url === 'null') {
          location.href = '/'
        }

        location.href = response.data.url
      }, 500)
    }).catch(() => {
    })
  }
}
</script>

<style scoped>
.loading {
  text-align: center;
  margin-top: 100px;
  color: #2c3e50;
}

.error {
  margin-top: 100px;
  font-size: 14px;
  background-color: red;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: white;
  border-radius: 4px;
}
</style>
