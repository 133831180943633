<template>
  <div>
    <div class="funnel">
      <div class="funnel-name" style="align-items: center">
        <v-select class="funnels-select" :searchable="false" :filterable="false" :clearable="false" style="width: 340px; font-weight: bold; font-size: 15px; margin-top: 0px;" @option:selected="onChangeFunnelSelected()" v-model="this.selectedFunnelList" :options="this.funnels" label="name"  :selectable="(option) => option.id !== this.selectedFunnelList.id "></v-select>
        <i v-if="this.selectedFunnel && this.selectedFunnel.default_funnel===1" class="fas fa-star star-yellow" ></i>
        <i v-if="this.selectedFunnel && this.selectedFunnel.default_funnel===0" class="fas fa-star star-grey"></i>
        <router-link to="/settings/funnels">
          <button class="button button-small button-light">{{ $t('settings.funnels.button_edit') }}</button>
        </router-link>
        <i @click="openVideoHelp($t('help.links.pipeline'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
      </div>
      <div v-if="this.loggedUser && this.loggedUser.canSeeAllChats && this.organizationUsers" class="funnel-filters">
        <div class="funnel-filter">
          <v-select v-if="organizationUsers" style="margin-top: 5px; min-width: 250px"  @option:selected="onChangeUserAssigned" @option:deselected="onChangeUserAssigned" multiple :options="getUsers()" label="name" v-model="this.selectedUsers"  :selectable="(option) => !this.selectedUsers.find((o) => o.id === option.id) " :placeholder="$t('contact.users_placeholder')">
            <template #option="option">
                    <span>
                      <i v-if="option.type==='user'" class="fas fa-user" aria-hidden="true"></i>
                      <i v-if="option.type==='team'" class="fas fa-users" aria-hidden="true"></i>
                      {{ option.name }}
                    </span>
            </template>
          </v-select>
        </div>
        <div class="funnel-filter">
          <label>{{ $t('settings.funnels.unassigned') }}</label>
          <input @change="onChangeUserAssigned" v-model="this.unassigned" type="checkbox">
        </div>
      </div>
      <div class="funnel-stages">
        <Stage v-for="(stage,idx) in selectedFunnel.stages" :index="idx" :stage="stage" :key="stage.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Stage from '@/components/home/pipelines/Stage'

export default {
  name: 'Funnel',
  components: {
    Stage
  },
  computed: mapGetters(['selectedFunnel', 'funnels', 'organizationUsers', 'teams', 'loggedUser']),
  data () {
    const selectedUsers = []
    let unassigned = true

    if (this.$route.name === 'PipelineFilteredAssigned') {
      unassigned = false
    }

    let filter = this.$route.query['filter[]']
    if (filter && typeof filter === 'string') {
      filter = [filter]
    }

    this.setFunnelFilter({ filter: filter, unassigned: unassigned })
    return {
      selectedUsers: selectedUsers,
      unassigned: unassigned,
      selectedFunnelList: this.selectedFunnel
    }
  },
  mounted () {
    this.selectedUsers = []

    let filter = ['all']
    if (this.$route.query['filter[]']) {
      filter = this.$route.query['filter[]']

      if (!Array.isArray(filter)) {
        filter = [filter]
      }
    }

    for (const id of filter) {
      let select = this.getUsers().find(el => el.id === id)
      if (!select) {
        select = this.teams.find(el => el.id === id)
      }

      if (select) {
        this.selectedUsers.push(select)
      }
    }

    if (this.loggedUser.canSeeAllChats === 0) {
      filter = [this.loggedUser.id]
      const select = this.getUsers().find(el => el.id === this.loggedUser.id)
      if (select) {
        this.selectedUsers.push(select)
      }
      this.unassigned = false
    }

    this.setFunnelFilter({ filter: filter, unassigned: this.unassigned }).then(() => {
      this.emitter.emit('updateFunnelStage')
    })

    this.selectedFunnel = this.funnels.find(funnel => funnel.id === this.selectedFunnel.id)
    this.selectedFunnelList = this.selectedFunnel
  },
  methods: {
    ...mapActions(['setFunnelFilter', 'setSelectedFunnel', 'updateFunnel']),
    openVideoHelp (url) {
      this.emitter.emit('openVideoHelp', url)
    },
    getUsers () {
      return [
        {
          id: 'all',
          name: this.$t('event.all_users'),
          short: this.$t('event.all_users')
        },
        ...this.getUsersAndTeams()
      ]
    },
    getUsersAndTeams () {
      const usersAndTeams = []

      for (const user of this.organizationUsers) {
        user.type = 'user'
        usersAndTeams.push(user)
      }

      for (const team of this.teams) {
        team.type = 'team'
        usersAndTeams.push(team)
      }
      return usersAndTeams
    },
    onChangeUserAssigned () {
      let funnelUrl = `/pipeline/${this.selectedFunnel.id}`

      if (this.unassigned === false) {
        funnelUrl = funnelUrl + '/assigned'
      }

      const selectedIds = []
      if (this.selectedUsers) {
        if (this.selectedUsers.length > 0) {
          const lastSelected = this.selectedUsers[this.selectedUsers.length - 1]
          if (lastSelected.id === 'all') {
            this.selectedUsers = [lastSelected]
          } else {
            const index = this.selectedUsers.findIndex(el => el.id === 'all')
            if (index !== -1) {
              this.selectedUsers.splice(index, 1)
            }
          }
        }

        if (this.selectedUsers.length === 0) {
          this.selectedUsers = [
            {
              id: 'all',
              name: this.$t('event.all_users')
            }
          ]
        }

        funnelUrl = funnelUrl + '?'

        let count = 0
        for (const option of this.selectedUsers) {
          if (count > 0) {
            funnelUrl = funnelUrl + '&'
          }

          funnelUrl = funnelUrl + `filter[]=${option.id}`

          count++
          selectedIds.push(option.id)
        }
      }

      this.$router.push(funnelUrl)
      localStorage.funnelUrl = funnelUrl

      this.setFunnelFilter({ filter: selectedIds, unassigned: this.unassigned }).then(() => {
        this.emitter.emit('updateFunnelStage')
      })
    },
    onChangeFunnelSelected () {
      this.setSelectedFunnel(this.selectedFunnelList).then(() => {
        this.onChangeUserAssigned()
      })
    }
  }
}
</script>

<style scoped>
.funnel {
  padding: 20px;
  background-color: #f8f9fa;
  position: relative;
}

.funnel-stages {
  width: calc(100% + 20px);
  margin-top: 20px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - 164px);
}

.funnel-name {
  position: relative;
  display: inline-flex;
}

.funnel-name i.fa-star {
  font-size: 15px;
  margin: 0px 10px;
}

.funnel-name .button {
  height: 28px;
  line-height: 30px;
  padding: 0px 10px;
  font-weight: 400;
  position: relative;
  width: fit-content;
  bottom: unset;
  font-size: 14px;
}

.button-light {
  color: #111b21;
  background-color: #EDEFF1;
}

.funnel-filters{
  position: absolute;
  right: 30px;
  top: 6px;
}

.funnel-filters .funnel-filter {
  width: 100%;
  text-align: right;
  margin-bottom: 3px;
}

.funnel-filters label {
  margin-right: 6px;
  width: calc(100% - 400px);
  text-align: right;
}

.user-filter {
  width: 200px;
  height: 30px;
  margin-left: 10px;
}

.help {
  font-size: 25px;
  cursor: pointer !important;
  text-align: center;
  margin-left: 15px;
  margin-top: -1px;
}

.star-grey  {
  color: #CFD0D0;
}

.star-yellow {
  color: #F3C967;
}

</style>

<style>
.funnel .funnels-select .vs__selected {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #111b21;
  display: inline-block;
  margin-left: 0;
  padding-left: 0;
}

.funnel .funnels-select .vs__selected-options {
  flex-wrap: nowrap;
  width: 320px;
}

.funnels-select.v-select .vs__dropdown-toggle {
  border: 0;
}

</style>
