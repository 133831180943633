<template>
  <router-link to="/pricing">
    <div class="content">
      <h1>600%</h1>
      <img  class="marker" src="@/assets/two-arrows.svg" alt="circle">
      <span class="figure">{{ $t('integrations.figure-sales.title') }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'figure-sales'
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
.content{
  text-align: center;
}
h1 {
  text-align: center;
  position: relative;
  top: -20px;
  font-size: 70px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 25px
}
img.marker {
  position: absolute;
  top: 87px;
  width: 290px;
  left: 56px;
}

</style>
