<template>
  <div v-if="showTimer === true" class="overlay">
    <div class="modal">
      <div class="wrapper">
        <div class="left">
        </div>
        <div class="right">
          <div class="timer">
            <img src="../../assets/qr-import-waiting.png" />

            <br>
            <div style="text-align: center">
              <vue-countdown :time="15000" @progress="onCountdownProgress" @end="onCountdownEnd" v-slot="{ seconds }">{{ getSeconds(seconds) }}</vue-countdown>
            </div>
            <br>

            <div class="progress-bar">
              <div id="progress-bar-progress" class="progress-bar-progress"></div>
            </div>

            <h4>{{ $t('wabusiness.synching.title') }}</h4>
            <h5>{{ $t('wabusiness.synching.subtitle') }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'QRConnecting',
  data () {
    return {
    }
  },
  computed: mapGetters(['showTimer']),
  methods: {
    ...mapActions([]),
    onCountdownProgress (data) {
      const progressBarWidth = 350
      const totalSeconds = 15000
      const totalSecondsRemaining = data.totalMilliseconds

      const completion = 1 - (totalSecondsRemaining / totalSeconds)
      console.log(completion)
      const progressWidth = progressBarWidth * completion

      document.getElementById('progress-bar-progress').style.width = progressWidth + 'px'
    },
    onCountdownEnd: function () {
      const sessionToken = sessionStorage.getItem('accessTokensSession')
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      Cookies.set('accessToken', sessionToken, { expires: 7, path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

      if (window.location &&
          window.location.host &&
          window.location.host === 'www.manycontacts.com') {
        sessionStorage.removeItem('accessTokensSession')
        localStorage.removeItem('accessTokens')
      }

      window.location.href = process.env.VUE_APP_APP_HOST + '/pipelines?ft=true'
    },
    getSeconds (seconds) {
      return seconds >= 10 ? seconds : '0' + seconds
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  height: 490px;
  margin-left: -415px;
  left: 50%;
  top: 175px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: fit-content;
  padding: 0px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  border-radius: 10px;
}

.number-of-users {
  display: flex;
}

h3 {
  font-size: 24px;
  color: #25D366;
}

.left {
  width: 450px;
  background-image: url("../../assets/perro3.jpeg");
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.right {
  width: 370px;
  padding: 15px;
  position: relative;
  height: calc(100% - 30px);
}

.button {
  width: calc(100% - 30px);
  background-color: #25D366;
}

.wrapper {
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  display: flex;
  overflow: hidden;
}

.number-of-users .users-selector {
  width: 33%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
}

.number-of-users .users-selector:hover {
  background-color: #ffffff;

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector.active {
  background-color: rgba(51, 75, 250, 0.07);

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector:first-of-type {
  margin-left: 0;
}

.number-of-users .users-selector:last-of-type {
  margin-right: 0;
}

.errors {
  background-color: #ff7675;
  text-align: center;
  line-height: 40px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.language-selector {
  position: absolute;
  bottom: -33px;
  right: 20px;
}
</style>
