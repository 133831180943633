<template>
  <router-link to="/pricing">
    <div class="organization">
      <img src="@/assets/ebike-logo.png" alt="eBike">
      <span>eBike</span>
    </div>
    <div class="verified">
      <img src="@/assets/verified.png" alt="verified">
    </div>
    <div class="testimonials-img">
      <img src="@/assets/ceo-ebike.png" alt="Miguel">
    </div>
    <div class="arrow">
      <svg viewBox="0 0 63 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.04688 59.0269C14.1614 59.0269 25.8612 58.8606 34.8172 47.8822C40.3304 41.1241 53.5496 12.8594 57 5" stroke="#4285f4" stroke-width="2" stroke-linecap="round"/>
        <path d="M60.5281 20.7208C60.5281 15.5031 64.7485 -1.25718 55.2637 1.79151C50.7116 3.25469 44.4811 8.45974 42.3828 12.6563" stroke="#4285f4" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
    <div class="description">
      <div class="title">
        {{ $t('integrations.review-ebike.title') }}
      </div>
      <div class="quote">
        {{ $t('integrations.review-ebike.quote') }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'review-ebike'
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
.arrow {
  z-index: 5;
  position: absolute;
  top: 5px;
  left: 110px;
}
svg {
  transform: rotate(55deg);
  width: 100px;
  height: 145px;
  margin: 0 auto;
}
.organization {
  padding-left: 10px;
}
.organization img {
  width: 30px;
  vertical-align: middle;
  margin-right: 5px;
}
.organization span {
  font-weight: 500;
  font-size: 16px;
}
.verified {
  position: absolute;
  top: 29px;
  left: 115px;
}
.verified img {
  width: 15px;
}
.testimonials-img {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 1;
}
.testimonials-img img {
  width: 120px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
}

.description {
  position: absolute;
  bottom: 20px;
  border: 1px solid #dfe6e9;
  border-radius: 6px;
  width: 330px;
  padding: 10px;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.quote {
  color: #6b7280;
}
</style>
