<template>
  <nav>
   <p>
     <span v-html="$t('migration.alert.text')"></span>
     <button @click="clickContactUs()" class="button">{{ $t('migration.alert.button') }}</button>
   </p>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MigrationAlert',
  methods: {
    ...mapActions([
      'fetchOrganization',
      'getSupportContact',
      'fetchContacts',
      'fetchConversation',
      'clearSelectedContact'
    ]),
    clickContactUs () {
      this.$router.push('/chats').then(() => {
        this.getSupportContact().then(contact => {
          this.clearSelectedContact()
          this.setMessageCache(contact.id, this.$t('migration.alert.wame_text'))
          this.fetchContacts({ filter: 1, page: 0 }).then(() => {
            this.fetchConversation({ contact: contact, messageId: null }).then(() => {
              this.emitter.emit('onDiscardFile')
            })
          })
        })
      })

      this.close()
    },
    setMessageCache (contactId, text) {
      const cacheMessageKey = `message-${contactId}`
      if (text === '') {
        localStorage.removeItem(cacheMessageKey)
        return
      }
      localStorage.setItem(cacheMessageKey, text)
    }
  },
  computed: mapGetters(['organization'])
}
</script>

<style scoped>
nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #e74c3c;
  color: white;
  z-index: 4;
  text-align: left;
  display: none;
  height: 60px;
}

.migrationAlert nav {
  display: flex;
}

p {
  margin: 0;
  padding: 0;
  margin-left: 50px;
  margin-top: 12px;
}

button {
  position: absolute;
  right: 50px;
  top: 10px;
  padding: 0 10px;
  width: fit-content;
}

</style>
