<template>
  <div>
    <Alerts />
    <Navbar />
    <div class="content">
      <Sidebar />
      <Content />
    </div>
  </div>
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import Navbar from '@/components/home/Navbar'
import Sidebar from '@/components/home/Sidebar'
import Content from '@/components/home/emails/Content'
import NewMessage from '@/components/modals/NewMessage'

import { mapActions, mapGetters } from 'vuex'

import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'Emails',
  methods: {
    ...mapActions(['setPage'])
  },
  props: {
    page: Number
  },
  computed: mapGetters(['newMessageOpen']),
  created () {
    if (undefined !== this.page && this.page == null) {
      this.setPage(this.page)
    }
  },
  components: {
    Alerts,
    Navbar,
    Sidebar,
    Content,
    NewMessage
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
