<template>
  <div id="conversation" class="conversation">
    <div class="chat conversation-container" id="conversation-container" v-on:scroll="scrollFunction">
      <div class="reminders-pending">
        <Bubble :key="message.id" v-for="message in pendingReminders" :message="message" />
        <div class="scheduled" :key="message.id" v-for="message in scheduledMessages" :message="message">
          <img style="height: 16px; margin-left: 5px; margin-top: 5px;" src="../../../assets/send-scheduled_black.svg" v-tooltip.left="{ content: $t('event.scheduled_send') }" />
          <span class="scheduled-date">{{ $moment(message.date).format('DD MMM YYYY HH:mm') }}</span>
          <div class="scheduled-body">{{ message.text }}</div>
          <i @click="onClickDeleteScheduled(message.id)" class="scheduled-delete fas fa-times" v-tooltip.right="{ content: $t('event.cancel_scheduled_send') }"></i>
        </div>
      </div>
      <Bubble :key="message.id" v-for="message in contactMessages" :message="message" />
    </div>
    <div :hidden="contactMessages && contactMessages.length > 0" class="whatsapp-policy" v-if="!isLast24hMessage()">
      <img style="position: absolute" height="20" src="../../../assets/WhatsApp_icon.svg" />
      <div class="close-policy" @click="closePolicy()"><i class="fas fa-times"></i></div>
      <div v-html="$t('whatsapp.policy')"></div>
    </div>
    <div :hidden="!dragActive" id="uploader" class="uploader">
      <div class="uploader-container">
        <div>
          <i class="fas fa-paperclip"></i>
          <br>
          {{ $t('messages.new.dragAndDrop') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bubble from './Bubble'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Messages',
  data () {
    return {
      lastScrollPosition: null,
      busy: false,
      dragActive: false
    }
  },
  computed: mapGetters(['contactMessages', 'selectedContact', 'organization', 'pendingReminders', 'scheduledMessages']),
  components: {
    Bubble
  },
  methods: {
    ...mapActions(['fetchConversationPreviousMessages', 'deleteMessageScheduled', 'fetchContact']),
    isLast24hMessage () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      if (this.selectedContact.isInternalSupport === 1) {
        return true
      }

      if (this.selectedContact.user_id) {
        return true
      }

      if (!this.contactMessages || this.contactMessages.length === 0) {
        return false
      }

      return new Date(this.selectedContact.lastInbound).getTime() > (new Date().getTime() - (1 * 24 * 60 * 60 * 1000))
    },
    closePolicy () {
      document.getElementsByClassName('whatsapp-policy')[0].style.display = 'none'
    },
    scrollFunction (event) {
      if (event.currentTarget.scrollTop <= 200 &&
        event.currentTarget.scrollTop < this.lastScrollPosition) {
        if (this.busy) {
          return
        }

        const scrollHeight = event.currentTarget.scrollHeight

        this.busy = true
        this.fetchConversationPreviousMessages().then(() => {
          const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
          if (conversationContainerEl) {
            conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight - scrollHeight
          }

          this.busy = false
        })
      }
      this.lastScrollPosition = event.currentTarget.scrollTop
    },
    onClickDeleteScheduled (scheduledId) {
      let scrollTop = null
      const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
      if (conversationContainerEl) {
        scrollTop = conversationContainerEl.scrollTop
      }

      this.deleteMessageScheduled({ scheduledId: scheduledId, contactId: this.selectedContact.id }).then(() => {
        this.fetchContact(this.selectedContact.id).then(() => {
          if (scrollTop !== null && conversationContainerEl) {
            conversationContainerEl.scrollTop = scrollTop
          }
        })
      })
    }
  },
  mounted () {
    const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
    if (conversationContainerEl) {
      conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
      const messageInputEl = document.getElementById('message-input')
      if (messageInputEl) {
        messageInputEl.focus()
      }
    }

    const self = this
    setTimeout(() => {
      const dragAndDropSupported = (function () {
        const div = document.createElement('div')
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
      }())

      if (dragAndDropSupported) {
        const dragableEl = document.getElementById('conversation-container')
        const uploaderEl = document.getElementById('uploader')

        // eslint-disable-next-line no-inner-declarations
        function preventDefaults (e) {
          e.preventDefault()
          e.stopPropagation()
        }

        // eslint-disable-next-line no-inner-declarations
        function highlight (e) {
          if (!self.isLast24hMessage()) {
            return
          }

          self.dragActive = true
        }

        // eslint-disable-next-line no-inner-declarations
        function unhighlight (e) {
          self.dragActive = false
        }

        // eslint-disable-next-line no-inner-declarations
        function handleDrop (e) {
          e.preventDefault()
          e.stopPropagation()

          const dt = e.dataTransfer
          const files = dt.files
          self.emitter.emit('loadFile', files)
        }

        if (!uploaderEl || !dragableEl) {
          return
        }

        uploaderEl.addEventListener('drop', handleDrop, false)

        ;['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventName => {
          dragableEl.addEventListener(eventName, preventDefaults, false)
          uploaderEl.addEventListener(eventName, preventDefaults, false)
        })

        ;['dragenter', 'dragover'].forEach(eventName => {
          dragableEl.addEventListener(eventName, highlight, false)
        })

        ;['dragleave', 'drop'].forEach(eventName => {
          uploaderEl.addEventListener(eventName, unhighlight, false)
        })
      }
    }, 2000)
  },
  updated () {
    const whatsappPolicyEl = document.getElementsByClassName('whatsapp-policy')[0]
    if (whatsappPolicyEl) {
      whatsappPolicyEl.style.removeProperty('display')
    }

    const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
    if (conversationContainerEl) {
      conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
      const messageInputEl = document.getElementById('message-input')
      if (messageInputEl) {
        messageInputEl.focus()
      }
    }
  }
}
</script>

<style scoped>
.conversation {
  text-align: left;
  height: calc(100% - 50px);
  position: relative;
  z-index: 0;
  background-color: rgba(240, 224, 199, 0.38);
  background-image:url(../../../assets/whatsapp-bg.png);
  background-repeat:repeat;
  background-size: 412.5px 749.25px;
}

.conversation .conversation-container {
  height: calc(100% - 60px);
  overflow-x: hidden;
  padding: 60px 16px 0;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

.whatsapp-policy {
  background-color: #d9fdd3;
  position: absolute;
  margin: 0 50px;
  padding: 20px;
  bottom: 0;
  z-index: 1000;

  -webkit-border-top-left-radius: 7.5px;
  -webkit-border-top-right-radius: 7.5px;
  -moz-border-radius-topleft: 7.5px;
  -moz-border-radius-topright: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
}

.whatsapp-policy .close-policy {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.uploader {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  top: 50px;
  left: 0;
  background-color: rgba(255,255,255,0.9);
  z-index: 2000;
  text-align: center;
}

.uploader .uploader-container {
  position: absolute;
  font-size: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  margin: 30px 30px 30px 30px;
  border: 1px dashed #667781;
  border-radius: 10px;
  color: #667781;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reminders-pending {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  border-bottom: 1px solid #e3e8ea;
  padding: 0;
  width: 100%;
  flex-direction: column;
  max-height: 30vh;
  overflow: scroll;
}

.scheduled {
  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 5px 25px 5px 25px;
  word-wrap: break-word;
  z-index: 2;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
  background: #d9fdd3;
  border-radius: 7.5px;
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  max-width: calc(100% - 50px - 16px);
  width: calc(100% - 50px - 16px);
  height: inherit;
  flex-wrap: wrap;
}

.scheduled-date {
  color: #111b21;
  background-color: #78b66e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  width: fit-content;
  text-align: center;
  line-height: 19px;
  position: relative;
  margin: 0 10px;
  right: unset;
  bottom: unset;
}

.scheduled-body {
  padding-top: 4px;
}

.scheduled-delete {
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
}
</style>
