<template>
  <div class="search">
    <input @clearSearch="this.message = ''" @input="onInputSearch" id="input-search" type="search" v-model="message" :placeholder="$t('navbar.search_placeholder')" autocomplete="false">
    <i :class="loadingSearch ? 'fa-solid fa-spinner fa-spin-pulse' : 'fas fa-search'"></i>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InputSearch',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapGetters(['tags', 'searchString', 'loadingSearch']),
    message: {
      get () {
        return this.searchString
      },
      set (value) {
        this.setSearchString(value)
      }
    }
  },
  methods: {
    ...mapActions(['querySearch', 'setSearchString', 'clearSearch']),
    onInputSearch () {
      clearTimeout(this.timer)

      if (this.searchString === '') {
        this.clearSearch()
        return
      }

      this.timer = setTimeout(() => {
        if (this.searchString.length < 2) {
          return
        }
        this.querySearch({ search: this.searchString, tagIds: null })
      }, 1000)
    }
  }
}
</script>

<style scoped>
.search {
  margin-left: 20px;
  width: 100%;
  max-width: 250px;
  position: absolute;
  right: 140px;
  z-index: 2000000;
}

.search input {
  outline: none;
  position: fixed;
  max-width: 250px;
  background-color: #F1F3F4;
  border: 0;
  height: 40px;
  padding: 0 16px 0 45px;
  margin-top: -2px;
  display: block;
  width: 100%;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  z-index: 2000000;
}

.search .fa-search, .search .fa-spinner {
  position: absolute;
  left: 16px;
  top: 12px;
  font-size: 16px;
  color: #6F6F70;
  z-index: 2000000;
}

.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modalSearch {
  z-index: 1000001;
  background-color: #ffffff;
  width: 600px;
  height: 420px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
  padding-top: 25px;
}

h4 {
  padding: 0;
  margin: 0;
}

.messages {
  width: 60%;
  float: left;
  border-right: 1px solid #EDEFF1;
}

.contacts {
  width: calc(40% - 1px);
  float: left;
}

.container {
  padding: 10px;
}

.tags-container {
  border-bottom: 1px solid #EDEFF1;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}

.tags .tag {
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  height: 316px;
  overflow: scroll;
}

.mobile .search {
  right: 20px;
  width: 150px;
}

.mobile #input-search {
  width: 150px;
}
</style>
