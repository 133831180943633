import axios from 'axios'
import Cookies from 'js-cookie'

const state = {
  partner: null,
  localStorageToken: null,
  sessionStorageIndex: null,
  userIsAutenticate: null
}

function initUser () {
  // FIXME: Remove me after some days from deployed in production
  const accessTokenCookie = Cookies.get('accessToken')
  if (accessTokenCookie) {
    sessionStorage.setItem('accessTokensSession', accessTokenCookie)
    Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
    Cookies.remove('accessToken', { path: '/', domain: 'app' + process.env.VUE_APP_COOKIE_HOST })
    Cookies.remove('accessToken')
    Cookies.remove('accessToken', { path: '', domain: 'app' + process.env.VUE_APP_COOKIE_HOST })
  }

  if (sessionStorage.getItem('accessTokensSession')) {
    state.userIsAutenticate = sessionStorage.getItem('accessTokensSession')
    let loaded = false

    const localToken = localStorage.getItem('accessTokens')
    if (localToken) {
      const jsonTokens = JSON.parse(localToken)
      if (jsonTokens) {
        for (const key in jsonTokens) {
          if (key === sessionStorage.getItem('accessTokensSession')) {
            loaded = true
          }
        }
      }
    }

    if (!loaded) {
      axios.get(`${process.env.VUE_APP_SERVERHOST}api/user`, {
        headers: {
          token: sessionStorage.getItem('accessTokensSession')
        }
      }).then((res) => {
        let jsonTokens = {}
        const browserTokens = JSON.parse(localStorage.getItem('accessTokens'))
        if (browserTokens) {
          jsonTokens = browserTokens
        }
        jsonTokens[sessionStorage.getItem('accessTokensSession')] = {
          id: res.data.id,
          email: res.data.email,
          name: res.data.name,
          date: new Date()
        }
        localStorage.setItem('accessTokens', JSON.stringify(jsonTokens))
      }).catch(() => {
        sessionStorage.removeItem('accessTokensSession')
        location.href = process.env.VUE_APP_WEB_HOST_LOGIN
      })
    }
  } else {
    const localToken = localStorage.getItem('accessTokens')
    if (localToken) {
      const jsonTokens = JSON.parse(localToken)
      if (jsonTokens) {
        for (const key in jsonTokens) {
          state.userIsAutenticate = key
          sessionStorage.setItem('accessTokensSession', key)
          break
        }
      }
    }
  }

  if (state.userIsAutenticate === null) {
    sessionStorage.removeItem('accessTokensSession')
  }
}
initUser()

const getters = {
  userIsAutenticate: (state) => state.userIsAutenticate,
  partner: (state) => state.partner
}
const actions = {
  async loginExists ({ commit }, data) {
    const response = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/login`, { params: data })
    return response.data
  },
  async login ({ commit, dispatch }, data) {
    let jsonTokens = {}

    const responseToken = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/login`, data, {})
    const userData = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/user`, {
      headers: {
        token: responseToken.data.token
      }
    })
    const browserTokens = JSON.parse(localStorage.getItem('accessTokens'))
    if (browserTokens) {
      jsonTokens = browserTokens
    }
    jsonTokens[responseToken.data.token] = {
      id: userData.data.id,
      email: userData.data.email,
      name: userData.data.name,
      date: new Date()
    }
    delete jsonTokens.null
    localStorage.setItem('accessTokens', JSON.stringify(jsonTokens))
    sessionStorage.setItem('accessTokensSession', responseToken.data.token)

    await commit('setUserIsAutenticate', responseToken.data.token)
  },
  async loadSession ({ commit }, token) {
    let jsonTokens = {}

    const userData = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/user`, {
      headers: {
        token: token
      }
    })
    const browserTokens = JSON.parse(localStorage.getItem('accessTokens'))
    if (browserTokens) {
      jsonTokens = browserTokens
    }
    jsonTokens[token] = {
      id: userData.data.id,
      email: userData.data.email,
      name: userData.data.name,
      date: new Date()
    }
    delete jsonTokens.null
    localStorage.setItem('accessTokens', JSON.stringify(jsonTokens))
    sessionStorage.setItem('accessTokensSession', token)

    await commit('setUserIsAutenticate', token)
  },
  async setAuthToken ({ commit }, token) {
    await commit('setUserIsAutenticate', token)
  },
  async setPartner ({ commit }, partner) {
    commit('setPartner', partner)
  }
}
const mutations = {
  setUserIsAutenticate: (state, userIsAutenticate) => (state.userIsAutenticate = userIsAutenticate),
  setPartner: (state, partner) => (state.partner = partner)
}

export default {
  state,
  getters,
  actions,
  mutations
}
