<template>
  <router-link to="/pricing">
    <div>
      <h1>{{ $t('integrations.marketing-sales.title') }}</h1>
      <img class="marker" src="@/assets/circle.svg" alt="circle">
      <button>{{ $t('integrations.marketing-sales.prices') }}</button>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'marketing-sales'
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
h1 {
  text-align: center;
  position: relative;
  top: 40px;
  font-size: 35px;
}
.module img.marker {
  position: absolute;
  top: 25px;
  width: 230px;
  left: 25px;
  transform: rotate(-10deg);
  font-size: 49px;
  zoom: 1.43;
}
.prices {
  position: relative;
  text-align: center;
  top: 100px;
}
.prices a {
  color: #4285f4;
  text-decoration: none;
  font-weight: bold;
}

button {
  width: calc(100% - 48px);
  height: 40px;
  position: absolute;
  bottom: 20px;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  color: #4285f4;
  border: 1px solid #4285f4;
  cursor: pointer;
}

button:hover {
  background-color: #4285f4;
  color: white;
}
</style>
