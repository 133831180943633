<template>
  <div class="content">
    <div class="header">
      <h1 v-if="!this.partner" id="module-content-title">{{ $t('modules.title') }}{{ getSector() }}</h1>
      <h1 v-if="this.partner" id="module-content-title">{{ $t('modules.title_referral', { name: getReferralReadableName(this.partner) }) }}</h1>
      <p>{{ $t('modules.subtitle') }}</p>
    </div>

    <div class="modules-tags">
      <router-link :to="{ name: 'HomeCategoryLocale', params: { category: tag.name, locale: this.$i18n.locale }}" v-for="tag in modulesTags" :key="tag.id">
        <div class="tag" :class="{active: this.activeTag === tag.name }" @click="onClickModuleTagFilter(tag)">
          {{ $t(`modules.tags.${tag.name}`) }}
        </div>
      </router-link>
    </div>

    <div v-if="this.activeTag" class="tag-seo-description">
      <p>{{ $t(`modules.tags.seo_description.${this.activeTag}`) }}</p>
    </div>

    <div class="modules" v-if="modules">
<!--      <PricingModule v-if="organization && organization.isInTrialPeriod"/>-->
      <TrialModule v-if="organization && organization.isInTrialPeriod" />
      <!-- WhatsAppAPIModule -->
      <FeatureModule :module="module" v-for="module in getModules()" :key="module.id" />
    </div>
    <h2 class="seo">{{ $t('integrations.title') }}</h2>
    <div class="modules" v-if="modulesSEO">
      <IntegrationModule :module="module" v-for="module in this.modulesSEO" :key="module.id" />
      <div style="width: 100%; text-align: center">
        <router-link :to="{ name: 'IntegrationsLocale', params: { locale: this.$i18n.locale } }">
          <button class="button button-see-all">{{ $t('modules.integrations.see_all') }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FeatureModule from '@/components/home/modules/FeatureModule'
import TrialModule from '@/components/home/modules/TrialModule'
import IntegrationModule from '@/components/home/modules/IntegrationModule'
// import PricingModule from '@/components/home/modules/PricingModule.vue'

export default {
  name: 'Content_Home',
  components: {
    TrialModule,
    FeatureModule,
    IntegrationModule
    // PricingModule
  },
  props: {
    sector: String,
    category: String
  },
  data () {
    return {
      titleResized: false,
      moduleList: [],
      activeTag: null,
      seoFilter: 'featured'
    }
  },
  computed: mapGetters([
    'organization',
    'modules',
    'organizationModules',
    'modulesTags',
    'partner',
    'modulesSEO'
  ]),
  methods: {
    ...mapActions(['fetchModules', 'fetchModulesTags']),
    onClickModuleTagFilter (tag) {
      if (this.activeTag === tag.name) {
        this.activeTag = null
        setTimeout(() => {
          this.$router.push('/')
        }, 200)
      } else {
        this.activeTag = tag.name
      }

      this.fetchModules({ tag: this.activeTag }).then((modules) => {
        this.moduleList = modules
      })
    },
    getReferralReadableName (str) {
      const splitStr = str.replaceAll('-', ' ').replaceAll('_', ' ').split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    },
    getSector () {
      if (!this.sector) {
        return ''
      }

      setTimeout(() => {
        const el = document.getElementById('module-content-title')
        if (el && this.titleResized === false) {
          this.titleResized = true
          el.style.fontSize = (40 * ((window.innerWidth - 200) / el.clientWidth)) + 'px'
        }
      }, 200)

      return ` ${this.$t('modules.for')} ${this.sector.replaceAll('-', ' ')}.`
    },
    getModules () {
      if (!this.moduleList) {
        return []
      }

      return this.moduleList.sort((a, b) => {
        if (!this.organizationModules) {
          return 0
        }

        if (this.organizationModules.includes(a.key) && !this.organizationModules.includes(b.key)) {
          return 1
        } else if (!this.organizationModules.includes(a.key) && this.organizationModules.includes(b.key)) {
          return -1
        }

        return 0
      })
    }
  },
  mounted () {
    this.fetchModules({ seo: this.seoFilter })

    if (this.moduleList.length !== 0) {
      return
    }

    if (!this.modules && this.category) {
      this.fetchModules({}).then(() => {
        this.fetchModules({ tag: this.category }).then((modules) => {
          this.moduleList = modules
          this.fetchModulesTags()
        })
      })
    } else {
      this.fetchModules({ tag: this.category }).then((modules) => {
        this.moduleList = modules
        this.fetchModulesTags()
      })
    }

    if (this.category) {
      this.activeTag = this.category
    }
  }
}
</script>

<style scoped>
.content {
  width: calc(100% - 111px);
  margin-left: 71px;
  padding: 20px;
  background-color: #ffffff;
  height: calc(100vh - 107px);
  overflow-y: scroll;
}

.header {
  margin-bottom: 30px;
  width: calc(100% - 40px);
}

.header h1 {
  padding: 0;
  margin: 0;
  /*width: max-content;*/
  font-size: 40px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  font-weight: 700;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2.seo {
  margin: 50px 0 30px 0;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modules {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}

.mobile .content, .mobile .hideSidebarChats .content {
  width: 100%;
  margin-left: 0px;
}

.modules-tags {
  min-height: 70px;
  width: 100%;
  margin-bottom: 20px;
}

.modules-tags .tag {
  padding: 1px 7px;
  text-decoration: none;
  float: left;
  margin: 13px 10px 0 0;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #757575;
  cursor: pointer;
}

.modules-tags .tag.active {
  color: #fff;
  background-color: #757575;
}

.tag-seo-description {
  margin: 30px 0;
  width: calc(100% - 40px);
  text-align: left;
}

.button-see-all {
  height: 40px;
  position: relative;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  color: #4285f4;
  border: 1px solid #4285f4;
  cursor: pointer;
  padding: 0 100px;
  max-width: 50%;
  margin: 40px 0;
}

</style>
