const state = {
  newMessageOpen: false,
  newMessageForm: 'whatsapp',
  openAvatarImage: false,
  newShortResponseOpen: false,
  newShortResponseData: {
    id: null,
    name: '',
    text: ''
  },
  newUserInviteOpen: false,
  newTagOpen: false,
  newTagData: {
    id: null,
    name: '',
    color: ['#F5C8D3', '#B3A0C9', '#7A6AA9', '#8DD4CD', '#FFDFD3', '#B6EECF', '#F9FDE1', '#FFE9EA', '#E0BBE4', '#957DAD', '#D291BC', '#FEC8D8', '#FFDFD3', '#ffb3ba', '#ffdfba', '#ffffba', '#baffc9', '#bae1ff', '#55efc4', '#81ecec', '#74b9ff', '#a29bfe', '#dfe6e9', '#00b894', '#00cec9', '#0984e3', '#6c5ce7', '#b2bec3', '#ffeaa7', '#fab1a0', '#ff7675', '#fd79a8', '#fdcb6e', '#e17055', '#d63031', '#e84393'][Math.floor(Math.random() * 35)]
  },
  newTeamOpen: false,
  newTeamData: {
    id: null,
    name: ''
  },
  openCRM: false,
  newPromoteOpen: false,
  newPromoteData: {
    id: null,
    number: '',
    text: ''
  },
  newCustomFieldOpen: false,
  newCustomFieldData: {
    id: null,
    name: ''
  },
  attachmentListOpen: false,
  eventsListOpen: false,
  newFunnelOpen: false,
  newFunnelData: {
    id: null,
    name: '',
    default_funnel: 0
  }
}
const getters = {
  newMessageOpen: (state) => state.newMessageOpen,
  newMessageForm: (state) => state.newMessageForm,
  openAvatarImage: (state) => state.openAvatarImage,
  newShortResponseOpen: (state) => state.newShortResponseOpen,
  newShortResponseData: (state) => state.newShortResponseData,
  newUserInviteOpen: (state) => state.newUserInviteOpen,
  newTagOpen: (state) => state.newTagOpen,
  newTagData: (state) => state.newTagData,
  newTeamOpen: (state) => state.newTeamOpen,
  newTeamData: (state) => state.newTeamData,
  openCRM: (state) => state.openCRM,
  newPromoteOpen: (state) => state.newPromoteOpen,
  newPromoteData: (state) => state.newPromoteData,
  newCustomFieldOpen: (state) => state.newCustomFieldOpen,
  newCustomFieldData: (state) => state.newCustomFieldData,
  attachmentListOpen: (state) => state.attachmentListOpen,
  eventsListOpen: (state) => state.eventsListOpen,
  newFunnelOpen: (state) => state.newFunnelOpen,
  newFunnelData: (state) => state.newFunnelData
}

const actions = {
  async toggleNewMessage ({ commit }) {
    commit('toggleNewMessage')
  },
  async setNewMessageForm ({ commit }, formView) {
    commit('setNewMessageForm', formView)
  },
  async closeNewMessage ({ commit }) {
    commit('closeNewMessage')
  },
  async toggleAvatarImage ({ commit }) {
    commit('toggleAvatarImage')
  },
  async closeAvatarImage ({ commit }) {
    commit('closeAvatarImage')
  },
  async toggleNewShortResponse ({ commit }) {
    commit('toggleNewShortResponse')

    setTimeout(() => {
      const el = document.getElementById('sr-title')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewShortResponseData ({ commit }, response) {
    commit('setNewShortResponseData', response)
  },
  async emptyNewShortResponseData ({ commit }) {
    commit('setNewShortResponseData', {
      id: null,
      name: '',
      text: ''
    })
  },
  async toggleNewUserInvite ({ commit }) {
    commit('toggleNewUserInvite')

    setTimeout(() => {
      const el = document.getElementById('sr-name')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async toggleNewTagOpen ({ commit }) {
    commit('toggleNewTag')

    setTimeout(() => {
      const el = document.getElementById('tag-name')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewTagData ({ commit }, response) {
    commit('setNewTagData', response)
  },
  async emptyNewTagData ({ commit }) {
    commit('setNewTagData', {
      id: null,
      name: '',
      color: ['#F5C8D3', '#B3A0C9', '#7A6AA9', '#8DD4CD', '#FFDFD3', '#B6EECF', '#F9FDE1', '#FFE9EA', '#E0BBE4', '#957DAD', '#D291BC', '#FEC8D8', '#FFDFD3', '#ffb3ba', '#ffdfba', '#ffffba', '#baffc9', '#bae1ff', '#55efc4', '#81ecec', '#74b9ff', '#a29bfe', '#dfe6e9', '#00b894', '#00cec9', '#0984e3', '#6c5ce7', '#b2bec3', '#ffeaa7', '#fab1a0', '#ff7675', '#fd79a8', '#fdcb6e', '#e17055', '#d63031', '#e84393'][Math.floor(Math.random() * 35)]
    })
  },
  async toggleNewTeamOpen ({ commit }) {
    commit('toggleNewTeam')

    setTimeout(() => {
      const el = document.getElementById('team-name')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewTeamData ({ commit }, response) {
    commit('setNewTeamData', response)
  },
  async emptyNewTeamData ({ commit }) {
    commit('setNewTeamData', {
      id: null,
      name: ''
    })
  },
  async setOpenCRM ({ commit }, open) {
    commit('setOpenCRM', open)
  },
  async toggleNewPromote ({ commit }) {
    commit('toggleNewPromote')

    setTimeout(() => {
      const el = document.getElementById('sr-title')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewPromoteData ({ commit }, response) {
    commit('setNewPromoteData', response)
  },
  async emptyNewPromoteData ({ commit }, number) {
    commit('setNewPromoteData', {
      id: null,
      number: number || '',
      text: ''
    })
  },
  async toggleNewCustomField ({ commit }) {
    commit('toggleNewCustomField')

    setTimeout(() => {
      const el = document.getElementById('cf-name')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewCustomFieldData ({ commit }, response) {
    commit('setNewCustomFieldData', response)
  },
  async emptyNewCustomFieldData ({ commit }) {
    commit('setNewCustomFieldData', {
      id: null,
      name: ''
    })
  },
  async toggleAttachmentListOpen ({ commit }) {
    commit('toggleAttachmentListOpen')
  },
  async toggleEventsListOpen ({ commit }) {
    commit('toggleEventsListOpen')
  },
  async toggleNewFunnel ({ commit }) {
    commit('toggleNewFunnel')

    setTimeout(() => {
      const el = document.getElementById('funnel-name')
      if (el) {
        el.focus()
      }
    }, 200)
  },
  async setNewFunnelData ({ commit }, response) {
    commit('setNewFunnelData', response)
  },
  async emptyNewFunnelData ({ commit }) {
    commit('setNewFunnelData', {
      id: null,
      name: ''
    })
  }
}
const mutations = {
  toggleNewMessage: (state) => (state.newMessageOpen = !state.newMessageOpen),
  closeNewMessage: (state) => (state.newMessageOpen = false),
  setNewMessageForm: (state, formView) => (state.newMessageForm = formView),
  toggleAvatarImage: (state) => (state.openAvatarImage = !state.openAvatarImage),
  closeAvatarImage: (state) => (state.openAvatarImage = false),
  toggleNewShortResponse: (state) => (state.newShortResponseOpen = !state.newShortResponseOpen),
  setNewShortResponseData: (state, response) => (state.newShortResponseData = response),
  toggleNewUserInvite: (state) => (state.newUserInviteOpen = !state.newUserInviteOpen),
  toggleNewTag: (state) => (state.newTagOpen = !state.newTagOpen),
  setNewTagData: (state, response) => (state.newTagData = response),
  toggleNewTeam: (state) => (state.newTeamOpen = !state.newTeamOpen),
  setNewTeamData: (state, response) => (state.newTeamData = response),
  setOpenCRM: (state, open) => (state.openCRM = open),
  toggleNewPromote: (state) => (state.newPromoteOpen = !state.newPromoteOpen),
  setNewPromoteData: (state, response) => (state.newPromoteData = response),
  toggleNewCustomField: (state) => (state.newCustomFieldOpen = !state.newCustomFieldOpen),
  setNewCustomFieldData: (state, response) => (state.newCustomFieldData = response),
  toggleAttachmentListOpen: (state) => (state.attachmentListOpen = !state.attachmentListOpen),
  toggleEventsListOpen: (state) => (state.eventsListOpen = !state.eventsListOpen),
  toggleNewFunnel: (state) => (state.newFunnelOpen = !state.newFunnelOpen),
  setNewFunnelData: (state, response) => (state.newFunnelData = response)
}

export default {
  state,
  getters,
  actions,
  mutations
}
