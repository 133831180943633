<template>
  <div class="content" v-if="loggedUser && organization">
    <div v-if="!processFinished">
      <div>
        <div>{{ $t('migration.organization') }} <br><b>{{ organization.name }}</b></div>
        <br>
        <div>{{ $t('migration.user') }} <br><b>{{ loggedUser.name }}</b></div>
        <br>
        <div>{{ $t('migration.email') }} <br><b>{{ loggedUser.email }}</b></div>
      </div>
      <br><br>
      <button @click="launchMigration" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">
        {{ $t('migration.submit') }}
      </button>
    </div>
    <div v-if="processFinished && organization" style="text-align: left">
      <div v-html="$t('migration.description1', { 'organizationNumber': organization.number })"></div>
      <br> <br>
      {{ $t('migration.description2') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Migration',
  data () {
    return {
      processFinished: false
    }
  },
  computed: mapGetters(['organization', 'loggedUser']),
  methods: {
    ...mapActions(['fetchOrganization', 'fetchLoggedUser', 'saveWabaId', 'saveSignupCode']),
    launchMigration () {
      const FB = window.FB
      const self = this

      FB.login(function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code
          self.saveSignupCode(code).then(() => {
            this.processFinished = true
          })
        } else {
          alert('Error', JSON.stringify(response))
        }
      }, {
        config_id: '1432539327659373',
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2,
          featureType: 'only_waba_sharing'
        }
      })
    }
  },
  created () {
    window.fbAsyncInit = function () {
      const FB = window.FB
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      })
    };

    (function (d, s, id) {
      var js; var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))

    const sessionInfoListener = (event) => {
      try {
        const data = JSON.parse(event.data)
        console.log(JSON.stringify(data))
        // {"data":{"waba_id":"303812662818476"},"type":"WA_EMBEDDED_SIGNUP","event":"FINISH_ONLY_WABA","version":"2"}
        if (data.data && data.data.waba_id) {
          console.log('Waba ID 1', data.data.waba_id)
          this.saveWabaId(data.data.waba_id)
        }
        if (data.waba_id) {
          console.log('Waba ID 2', data.data.waba_id)
          this.saveWabaId(data.waba_id)
        }
      } catch (e) {
        // Don’t parse info that’s not a JSON
        console.log('Non JSON Response', event.data, e)
      }
    }
    window.addEventListener('message', sessionInfoListener)

    this.fetchOrganization().then(() => {
      this.fetchLoggedUser()
    })
  }
}
</script>

<style scoped>
.content{
  width: 80%;
  position: absolute;
  left: 10%;
  text-align: center;
  font-size: 16px;
}
</style>
