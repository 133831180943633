import axios from 'axios'

const state = {
  zadarmaSettings: {},
  contact: null,
  callTimerInterval: null,
  callStatus: 'wait'
}
const getters = {
  zadarmaSettings: (state) => state.zadarmaSettings,
  callContact: (state) => state.contact,
  callTimerInterval: (state) => state.callTimerInterval,
  onGoingCall: (state) => state.callStatus === 'call'
}
const actions = {
  async fetchZadarmaSettings ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/zadarma`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    if (res.data.key && res.data.secret) {
      const scriptId = 'zadarmaCaller'

      if (document.getElementById(scriptId) === null) {
        const script = document.createElement('script')
        // For local testing purposes
        // script.setAttribute('src', 'https://www.manycontacts.com/api/zadarma/oid/1/uid/f84e310a-1ea4-4ba4-aa2d-35d748ecdb7b')
        script.setAttribute('src', `https://www.manycontacts.com/api/zadarma/oid/${res.data.organization_id}/uid/${res.data.user_id}`)
        script.setAttribute('id', scriptId)
        document.head.appendChild(script)
      }
    }

    commit('setZadarmaSettings', res.data)
  },
  async setZadarmaSettings ({ commit, rootState }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/zadarma`, {
      key: state.zadarmaSettings.key,
      secret: state.zadarmaSettings.secret
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  clearCallContact ({ commit }) {
    commit('changeCallContact', null)
  },
  startCall ({ commit, dispatch }, contact) {
    if (!state.callTimerInterval) {
      commit('changeCallContact', contact)
      const interval = setInterval(() => {
        if (window.zdrmWPhI && window.zdrmWPhI.status !== state.callStatus) {
          commit('changeCallStatus', window.zdrmWPhI.status)
          if (window.zdrmWPhI.status === 'wait') {
            dispatch('stopCall')
          }
        }
      }, 200)
      commit('setCallTimerInterval', interval)
    }

    if (typeof window.MCCall === 'function') {
      window.MCCall(`+${contact.number}`)
    } else {
      window.emitter.emit('openModulePopup', 'zadarma')
    }
  },
  stopCall ({ commit }) {
    commit('clearCallTimerInterval')
    commit('changeCallContact', null)
  }
}

const mutations = {
  setZadarmaSettings: (state, zadarmaSettings) => (state.zadarmaSettings = zadarmaSettings),
  changeCallStatus: (state, status) => (state.callStatus = status),
  changeCallContact: (state, contact) => (state.contact = contact),
  clearCallTimerInterval: (state) => {
    clearInterval(state.callTimerInterval)
    state.callTimerInterval = null
  },
  setCallTimerInterval: (state, intervalId) => (state.callTimerInterval = intervalId)
}

export default {
  state,
  getters,
  actions,
  mutations
}
