<template>
  <div class="settings-group">
    <h3>{{ $t('settings.permissions.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.permissions.description') }}</p>
      <button @click="openNewInvite" class="button" type="submit">{{ $t('settings.permissions.invite') }}</button>
    </div>
    <table>
      <thead>
      <th style="min-width: 55px;">{{ $t('settings.permissions.active') }}</th>
      <th>{{ $t('settings.permissions.name') }}</th>
      <th>{{ $t('settings.permissions.email') }}</th>
      <th style="min-width: 55px;">{{ $t('settings.permissions.canSee') }}</th>
      <th></th>
      </thead>
      <tbody>
      <tr :hidden="user.id == 0 || user.ai===true" :key="user.id" v-for="(user, index) in organizationUsers" class="principal">
        <td>
          <Switch v-on:update:checked="onChangeIsActive(user)" v-model:checked="user.isActive" />
        </td>
        <td>
          <div class="content-editable">
            <input @input="onContentEditable(user)" type="text" v-model="user.name" v-tooltip.top="{ content: $t('clickToEdit') }">
          </div>
        </td>
        <td>{{ user.email }}</td>
        <td>
          <tr>
            <td width="55px">
              <Switch :disabled="index === 0" v-on:update:checked="onChangeSeeSettings(user)" v-model:checked="user.canSeeSettings" />
            </td>
            <td>
              {{ $t('settings.permissions.seeSettings') }}
            </td>
        </tr>
        <tr>
          <td>
            <Switch :disabled="index === 0" v-on:update:checked="onChangeSeeAllChats(user)" v-model:checked="user.canSeeAllChats" />
          </td>
          <td>
            {{ $t('settings.permissions.seeAllChats') }}
          </td>
        </tr>
        <tr v-if="organization && organization.features.campaigns">
          <td>
            <Switch :disabled="index === 0" v-on:update:checked="onChangeSeeCampaigns(user)" v-model:checked="user.canSeeCampaigns" />
          </td>
          <td>
            {{ $t('settings.permissions.seeCampaigns') }}
          </td>
        </tr>
        </td>
        <td style="text-align: right;">
          <i v-if="user.id !== this.loggedUser.id && index !== 0" @click="delUser(user)" class="far fa-trash-alt"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-if="invitations && invitations.length > 0" class="settings-group">
    <h3>{{ $t('settings.permissions.pending_invitations.title') }}</h3>
    <table>
      <thead>
      <th>{{ $t('settings.permissions.pending_invitations.name') }}</th>
      <th>{{ $t('settings.permissions.pending_invitations.email') }}</th>
      <th>{{ $t('settings.permissions.pending_invitations.date') }}</th>
      <th></th>
      </thead>
      <tbody>
      <tr :key="invitation.id" v-for="invitation in invitations">
        <td>{{ invitation.name }}</td>
        <td>{{ invitation.email }}</td>
        <td>{{ $moment(invitation.createdAt).fromNow() }}</td>
        <td style="text-align: right;">
          <i @click="delInvitation(invitation)" class="far fa-trash-alt"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Switch from '../../../common/Switch'

export default {
  name: 'SectionPermissions',
  computed: mapGetters([
    'organization',
    'organizationUsers',
    'invitations',
    'loggedUser',
    'newUserInviteOpen'
  ]),
  methods: {
    ...mapActions([
      'fetchOrganizationUsers',
      'fetchInvitations',
      'updateUser',
      'deleteUser',
      'fetchLoggedUser',
      'toggleNewUserInvite',
      'deleteInvitation',
      'clearSelectedContact'
    ]),
    onChangeIsActive (user) {
      user.isActive = !user.isActive
      this.updateUser(user)
    },
    onChangeSeeSettings (user) {
      user.canSeeSettings = !user.canSeeSettings
      this.updateUser(user)
    },
    onChangeSeeAllChats (user) {
      user.canSeeAllChats = !user.canSeeAllChats
      this.updateUser(user)
    },
    onChangeSeeCampaigns (user) {
      user.canSeeCampaigns = !user.canSeeCampaigns
      this.updateUser(user)
    },
    delUser (user) {
      if (confirm(this.$t('settings.permissions.delete.confirm', { name: user.name }))) {
        this.deleteUser(user)
        this.clearSelectedContact()
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    },
    delInvitation (invitation) {
      if (confirm(this.$t('settings.permissions.pending_invitations.delete.confirm', { email: invitation.email }))) {
        this.deleteInvitation(invitation)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    },
    openNewInvite () {
      if (this.organization.watype === 'business' && this.organization.appsumo !== null && this.organizationUsers.length >= 2) {
        this.$router.push({ name: 'Pricing' })
        return
      }

      this.toggleNewUserInvite()
    },
    onContentEditable (user) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.updateUser(user)
      }, 200)
    }
  },
  created () {
    this.fetchOrganizationUsers(this.$t)
    this.fetchInvitations()
  },
  components: {
    Switch
  }
}
</script>

<style scoped>
p {
  width: calc(100% - 300px);
  max-width: 500px;
  float: left;
  margin: 0;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 40px;
}

tr.principal {
  height: 40px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

.content-editable {
  width: 175px;
}

.content-editable input {
  border: 1px solid transparent;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
}

.content-editable:after {
  display: none;
  content: "\f044";
  font: normal normal normal 14px/1 'Font Awesome 5 Free';
  color: #667781;
  z-index: 1;
  float: right;
  margin-top: 4px;
  margin-right: -9px;
}

.content-editable:hover input {
  border: 1px solid #667781;
  border-radius: 4px;
}

.content-editable:hover:after {
  display: block;
}
</style>
