import axios from 'axios'

const state = {
  searchResults: null,
  searchString: '',
  loadingSearch: false
}
const getters = {
  searchResults: (state) => state.searchResults,
  searchString: (state) => state.searchString,
  loadingSearch: (state) => state.loadingSearch
}

const actions = {
  async querySearch ({ commit, rootState }, { search, tagIds }) {
    commit('setLoadingSearch', true)
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/search?queryString=${search}&tags=${tagIds}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setSearchResults', res.data)
    commit('setLoadingSearch', false)
  },
  async clearSearch ({ commit }) {
    commit('setSearchString', '')
    commit('setSearchResults', null)
  },
  async setSearchString ({ commit }, text) {
    commit('setSearchString', text)
  }
}
const mutations = {
  setSearchResults: (state, results) => (state.searchResults = results),
  setSearchString: (state, text) => (state.searchString = text),
  setLoadingSearch: (state, loading) => (state.loadingSearch = loading)
}

export default {
  state,
  getters,
  actions,
  mutations
}
