<template>
  <nav>
    <p>
      <span v-html="$t('paywall.unpaid_invoice.text')"></span>
      <a v-if="organization" :href="organization.unpaidUrl"><button class="button">{{ $t('paywall.unpaid_invoice.button') }}</button></a>
    </p>
  </nav>
  <div v-if="organization && organization.longDue === true" class="overlay"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UnpaidInvoiceAlert',
  computed: mapGetters(['organization'])
}
</script>

<style scoped>
nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #e74c3c;
  color: white;
  text-align: left;
  display: none;
  height: 60px;
  z-index: 9000001;
}

.unpaidInvoiceAlert nav {
  display: flex;
}

p {
  margin: 0;
  padding: 0;
  margin-left: 50px;
  margin-top: 12px;
}

button {
  position: absolute;
  right: 50px;
  top: 10px;
  padding: 0 10px;
  width: fit-content;
}

</style>
