<template>
  <div>
    <Alerts />
    <Navbar :hide-search="true"/>
    <div class="content">
      <Sidebar />
      <SettingsContent />
    </div>
  </div>
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Navbar from '../components/home/Navbar'
import Sidebar from '../components/home/Sidebar'
import NewMessage from '../components/modals/NewMessage'
import SettingsContent from '../components/home/settings/SettingsContent'

import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'Settings',
  props: {
    section: String
  },
  methods: {
    ...mapActions(['setSection', 'setFilterView'])
  },
  computed: mapGetters(['newMessageOpen']),
  created () {
    this.setFilterView(null)
    this.setSection(this.section)
  },
  updated () {
    this.setSection(this.section)
  },
  components: {
    Alerts,
    SettingsContent,
    Navbar,
    Sidebar,
    NewMessage
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
