import { version } from '../../package.json'
import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

import Chats from '@/views/Chats'
import Calendar from '@/views/Calendar'
import Settings from '@/views/Settings'
import PageNotFound from '@/views/PageNotFound'
import Emails from '@/views/Emails'
import Stats from '@/views/Stats'
import Pipelines from '@/views/Pipelines'
import Campaigns from '@/views/Campaigns'
import Home from '@/views/Home'
import HomeFeature from '@/views/HomeFeature'
import Integrations from '@/views/Integrations'
import ConversationFrame from '@/views/ConversationFrame'

import Invite from '@/pages/Invite'
import Register from '@/views/Register'
import SSO from '@/pages/SSO'
import Stripe from '@/pages/Stripe'
import AutoLogin from '@/pages/AutoLogin'
import Login from '@/pages/Login'
import ChangeUser from '@/pages/ChangeUser'
import Migration from '@/pages/Migration'
import AdminPartnerAdd from '@/pages/admin/AdminPartnerAdd'
import AdminMigrateWABA from '@/pages/admin/AdminMigrateWABA'
// import Checkout from '@/views/Checkout.vue'

const defaultLocale = 'es'
const locales = ['es', 'en', 'pt', 'it']
const routes = [
  { path: '/', name: 'Home', component: Home },

  { path: '/:locale', name: 'HomeLocale', component: Home },

  { path: '/pricing', name: 'Pricing', component: Home },
  // { path: '/checkout', name: 'Checkout', component: Checkout },

  { path: '/t/:sector', name: 'HomeSector', component: Home, meta: { title: 'Home' }, props: true },
  { path: '/partner/:partner', name: 'HomePartner', component: Home, meta: { title: 'Home' }, props: true },

  { path: '/whatsapp/category/:category', name: 'HomeCategory', component: Home, props: true },
  { path: '/whatsapp/category/:category/:locale', name: 'HomeCategoryLocale', component: Home, props: true },

  { path: '/login', name: 'Login', component: Login, meta: { title: 'Login' } },
  { path: '/user/change/:token', name: 'ChangeUser', component: ChangeUser, meta: { title: 'ManyContacts' }, props: true },

  { path: '/invite/:token', name: 'Invite', component: Invite, props: true },
  { path: '/register', name: 'Register', component: Register, props: true },
  { path: '/register/t/:sector', name: 'RegisterSector', component: Register, props: true },
  { path: '/register/:partner', name: 'RegisterReferral', component: Register, props: true },
  { path: '/new-account', name: 'NewAccount', component: Register, props: true },
  { path: '/connector-select', name: 'ConnectorSelect', component: Register, props: true },
  { path: '/new-whatsapp-business', name: 'ConnectorWhatsAppBusiness', component: Register, props: true },
  { path: '/meta-payment-method', name: 'ConnectorWhatsAppAPIPaymentMethod', component: Register, props: true },

  { path: '/chats', name: 'Chats', component: Chats, props: { filterView: 0, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/chats/all', name: 'ChatsAll', component: Chats, props: { filterView: 1, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/chats/my-chats', name: 'ChatsMy', component: Chats, props: { filterView: 2, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/chats/starred', name: 'ChatsStarred', component: Chats, props: { filterView: 3, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/mentions', name: 'Mentions', component: Chats, props: { filterView: 4, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/reminders', name: 'Reminders', component: Chats, props: { filterView: 6, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/calendar', name: 'MyCalendar', component: Calendar, props: { filterView: 6, page: 0 }, meta: { title: 'Calendar', requiresAuth: true } },
  { path: '/calendar/:userAssigned', name: 'Calendar', component: Calendar, props: { filterView: 6, page: 0 }, meta: { title: 'Calendar', requiresAuth: true } },
  { path: '/emails', name: 'Emails', component: Emails, meta: { title: 'Emails', requiresAuth: true } },
  { path: '/internal', name: 'Internal', component: Chats, props: { filterView: 7, page: 0 }, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/conversation/:contactId', name: 'Conversation', component: Chats, props: { filterView: 1 }, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/conversation/:contactId/:messageId', name: 'ConversationMessage', component: Chats, props: { filterView: 1 }, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/conversation/frame/:number', name: 'ConversationFrame', component: ConversationFrame, props: true },

  { path: '/pipelines', name: 'PipelinesAll', component: Pipelines, meta: { title: 'Pipelines', requiresAuth: true } },
  { path: '/pipeline/:funnelId', name: 'PipelineFiltered', component: Pipelines, meta: { title: 'Pipelines', requiresAuth: true } },
  { path: '/pipeline/:funnelId/assigned', name: 'PipelineFilteredAssigned', component: Pipelines, meta: { title: 'Pipelines', requiresAuth: true } },
  { path: '/pipeline/:funnelId/unassigned', name: 'PipelineRedirect', redirect: '/pipelines', meta: { title: 'Pipelines', requiresAuth: true } },

  { path: '/email/:emailId', name: 'InboundEmail', component: Emails, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/chats/team/:filterView', name: 'ChatsTeam', component: Chats, props: { page: 0 }, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/settings', name: 'Settings', redirect: '/settings/profile', meta: { title: 'Chats', requiresAuth: true } },
  { path: '/settings/:section', name: 'SettingsSection', component: Settings, props: true, meta: { title: 'Settings', requiresAuth: true } },

  { path: '/stats', name: 'Stats', component: Stats, meta: { title: 'Chats', requiresAuth: true } },
  { path: '/campaigns', name: 'Campaigns', component: Campaigns, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/feature/:feature', name: 'FeatureRedirect', component: HomeFeature, props: true },
  { path: '/feature/:feature/:locale', name: 'FeatureRedirectLocale', component: HomeFeature, props: true },

  { path: '/whatsapp/integration/:feature', name: 'IntegrationRedirect', component: Integrations, props: true },
  { path: '/whatsapp/integration/:feature/:locale', name: 'IntegrationRedirectLocale', component: Integrations, props: true },

  { path: '/whatsapp/integrations', name: 'Integrations', component: Integrations, props: true },
  { path: '/whatsapp/integrations/:locale', name: 'IntegrationsLocale', component: Integrations, props: true },
  { path: '/whatsapp/integrations/:filter', name: 'IntegrationsFilter', component: Integrations, props: true },
  { path: '/whatsapp/integrations/:filter/:locale', name: 'IntegrationsFilterLocale', component: Integrations, props: true },

  { path: '/migration', name: 'Migration', component: Migration, meta: { title: 'WABA Migration', requiresAuth: true } },

  { path: '/stripe', name: 'Stripe', component: Stripe, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/sso/:partner/:token', name: 'SSO', component: SSO, props: true, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/login/:token', name: 'AutoLogin', component: AutoLogin, props: true, meta: { title: 'Chats', requiresAuth: true } },

  { path: '/admin/partner/add', name: 'AdminPartnerAdd', component: AdminPartnerAdd, props: true, meta: { title: 'Partner Add' } },
  { path: '/admin/migrate', name: 'AdminMigrateWABA', component: AdminMigrateWABA, props: true, meta: { title: 'Migrate WABAs' } },

  { path: '/404', component: PageNotFound, meta: { title: '404 Not Found' } },
  { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function manageMetaHeader (to) {
  try {
    let locale = to.params.locale || Cookies.get('ma-locale')
    if (!locale) {
      if (navigator.languages !== undefined) {
        locale = navigator.languages[0]
      }

      if (!locale) {
        locale = navigator.language
      }

      if (locale) {
        locale = locale.substr(0, 2)
      }
    }

    if (!locale || !locales.includes(locale)) {
      locale = defaultLocale
    }

    const notLocalizedPathName = to.name.replace('Locale', '')
    const notLocalizedRoute = router.resolve({ name: notLocalizedPathName, params: to.params })

    let notLocalizedUrl = 'default'
    if (notLocalizedRoute && notLocalizedRoute.href) {
      notLocalizedUrl = notLocalizedRoute.href
      notLocalizedUrl = notLocalizedUrl.replace('/', '')

      if (['IntegrationsFilter',
        'IntegrationsFilterLocale'].includes(to.name)) {
        notLocalizedUrl = notLocalizedUrl.replace(`/${to.params.filter}`, '')
      }
    }

    const resolvePath = (object, path, defaultValue) => path
      .split('/')
      .reduce((o, p) => o ? o[p] : defaultValue, object)

    const version = require('../../package.json').version
    let url = `${process.env.VUE_APP_LOCALES_HOST}metainfo/${locale}.json?version=${version}`
    if (process.env.NODE_ENV === 'development') {
      url = `${process.env.VUE_APP_LOCALES_HOST}metainfo/${locale}.json?timestamp=${new Date().getTime()}`
    }

    fetch(url).then((response) => {
      return response.json()
    }).then((messages) => {
      let meta = resolvePath(messages, notLocalizedUrl)

      if (!meta) {
        meta = resolvePath(messages, 'default')
      }

      if (!meta) {
        document.title = 'ManyContacts'
        return
      }

      if (meta.title) {
        document.title = meta.title + ' | ManyContacts'
      }

      if (meta.description) {
        const description = document.querySelector('meta[name="description"]')
        if (description) {
          description.remove()
        }

        const metaDescription = document.createElement('meta')
        metaDescription.name = 'description'
        metaDescription.content = meta.description
        document.head.appendChild(metaDescription)
      }
    })
  } catch (error) {
    console.error('metaHeader', error)
  }
}

function setCanonical (to) {
  try {
    if (!to.params.locale) {
      return
    }

    if (to.params.locale !== defaultLocale) {
      return
    }

    const canonical = document.querySelector('link[rel="canonical"]')
    if (canonical) {
      canonical.remove()
    }

    if ([
      'IntegrationRedirect',
      'IntegrationRedirectLocale',
      'Integrations',
      'IntegrationsLocale',
      'IntegrationsFilter',
      'IntegrationsFilterLocale',
      'FeatureRedirect',
      'FeatureRedirectLocale',
      'HomeFeature',
      'HomeFeatureLocale',
      'Home',
      'HomeLocale',
      'HomeCategory',
      'HomeCategoryLocale'
    ].includes(to.name)) {
      const notLocalizedPathName = to.name.replace('Locale', '')
      const notLocalizedRoute = router.resolve({ name: notLocalizedPathName, params: to.params })

      if (!notLocalizedRoute) {
        return
      }

      const canonicalEl = document.createElement('link')
      canonicalEl.setAttribute('rel', 'canonical')
      canonicalEl.setAttribute('href', process.env.VUE_APP_WEB_HOST + notLocalizedRoute.href.replace('/', ''))
      document.head.appendChild(canonicalEl)
    }
  } catch (e) {
    console.error('canonical', e)
  }
}

function setHrefLangs (to) {
  try {
    const hrefLangs = document.querySelectorAll('link[rel="alternate"]')
    hrefLangs.forEach((el) => {
      el.remove()
    })

    if ([
      'IntegrationRedirect',
      'IntegrationRedirectLocale',
      'Integrations',
      'IntegrationsLocale',
      'IntegrationsFilter',
      'IntegrationsFilterLocale',
      'FeatureRedirect',
      'FeatureRedirectLocale',
      'HomeFeature',
      'HomeFeatureLocale',
      'Home',
      'HomeLocale',
      'HomeCategory',
      'HomeCategoryLocale'
    ].includes(to.name)) {
      const notLocalizedPathName = to.name.replace('Locale', '')
      const notLocalizedRoute = router.resolve({ name: notLocalizedPathName, params: to.params })

      if (!notLocalizedRoute) {
        return
      }

      locales.forEach((locale) => {
        const localizedPathName = notLocalizedPathName + 'Locale'
        const localizedRoute = router.resolve({ name: localizedPathName, params: { ...to.params, locale } })

        if (!localizedRoute) {
          return
        }

        const hrefLangEl = document.createElement('link')
        hrefLangEl.setAttribute('rel', 'alternate')
        hrefLangEl.setAttribute('hrefLang', locale)
        hrefLangEl.setAttribute('href', process.env.VUE_APP_WEB_HOST + localizedRoute.href.replace('/', ''))
        document.head.appendChild(hrefLangEl)
      })

      // x-default
      const hrefLangEl = document.createElement('link')
      hrefLangEl.setAttribute('rel', 'alternate')
      hrefLangEl.setAttribute('hrefLang', 'x-default')
      hrefLangEl.setAttribute('href', process.env.VUE_APP_WEB_HOST + notLocalizedRoute.href.replace('/', ''))
      document.head.appendChild(hrefLangEl)
    }
  } catch (e) {
    console.error('hrefLangs', e)
  }
}

function lazyLoadIntegrationsLocale (to) {
  try {
    if ([
      'IntegrationRedirect',
      'IntegrationRedirectLocale',
      'Integrations',
      'IntegrationsLocale',
      'IntegrationsFilter',
      'IntegrationsFilterLocale'
    ].includes(to.name)) {
      const locale = Cookies.get('ma-locale')

      if (!window.i18n.global.te('integrations.all_integrations')) {
        let url = `${process.env.VUE_APP_LOCALES_HOST}modules/all_${locale}.json?version=${version}`
        if (process.env.NODE_ENV === 'development') {
          url = `${process.env.VUE_APP_LOCALES_HOST}modules/all_${locale}.json?timestamp=${new Date().getTime()}`
        }
        fetch(url).then((response) => {
          return response.json()
        }).then((data) => {
          window.i18n.global.mergeLocaleMessage(locale, data)
        })
      }
    }
  } catch (e) {
    console.error('lazyLoadIntegrationsLocale', e)
  }
}

router.beforeEach((to, from, next) => {
  setCanonical(to)
  setHrefLangs(to)
  lazyLoadIntegrationsLocale(to)

  if (to.params.locale) {
    let localeUrl = to.params.locale
    if (!locales.includes(to.params.locale)) {
      localeUrl = defaultLocale
    }

    const prevLocale = Cookies.get('ma-locale')
    if (prevLocale !== null && prevLocale !== localeUrl && !sessionStorage.getItem('accessTokensSession')) {
      Cookies.set('ma-locale', localeUrl)
      window.location.href = to.fullPath
      return
    }
  }

  if (to.meta.requiresAuth) {
    if (sessionStorage.getItem('accessTokensSession')) {
      if (sessionStorage.getItem('accessTokensSession') === 'null') {
        sessionStorage.removeItem('accessTokensSession')

        if (localStorage.getItem('accessTokens')) {
          const sessions = JSON.parse(localStorage.getItem('accessTokens'))

          if (sessions) {
            delete sessions.null

            localStorage.removeItem('accessTokens')
            localStorage.setItem('accessTokens', JSON.stringify(sessions))
          }
        }

        window.location.href = process.env.VUE_APP_WEB_HOST_LOGIN
        return
      }
    } else {
      window.location.href = process.env.VUE_APP_WEB_HOST_LOGIN
      return
    }
  }

  manageMetaHeader(to)

  next()
})

export default router
