<template>
  <div class="overlay" v-if="mustShow()">
    <div class="modal">
      <div style="overflow: scroll; height: 100%; width: 850px; margin: 0 auto;">
        <!--        <h3 v-if="options.title">{{ $t('pricing.title')}}</h3>-->
        <div v-if="!isUnpaid()" @click="close" class="modal-close"><i class="fas fa-times"></i></div>
        <!--        <p v-html="$t('pricing.subtitle')"></p>-->
        <div v-if="organization && organization.isInTrialPeriod && !isUnpaid()">
          <h3>
            <span>{{ $t('modules.trial.title') }} <span v-if="getTrialDaysRemaining() > 2">{{ $t('modules.trial.in') }}&nbsp;</span></span>
            <span v-if="getTrialDaysRemaining() > 2"> {{ getTrialDaysRemaining() }} {{ $t('modules.trial.days') }}</span>
            <span v-if="getTrialDaysRemaining() === 2">{{ $t('modules.trial.tomorrow').toLowerCase() }}</span>
            <span v-if="getTrialDaysRemaining() <= 1">{{ $t('modules.trial.today').toLowerCase() }}</span>
          </h3>
        </div>
        <div v-if="!organization" class="top-separate">
          <router-link :to="{ name: 'Register' }">
            <button class="button button-pay">{{ $t('pricing.cta') }}</button>
          </router-link>
        </div>
        <div v-if="organization" :class="{ 'top-separate': !organization || !organization.isInTrialPeriod || isUnpaid() }">
          <a :href="this.link">
            <button class="button button-pay">{{ $t('pricing.activate_now') }}</button>
          </a>
        </div>
        <div class="prices">
          <div class="price premium">
            <ul>
              <li class="title">{{ $t('pricing.premium.title') }}</li>
              <li class="amount"><span>{{ $t('pricing.premium.price') }}</span></li>
              <li v-html="$t('pricing.premium.f0')"></li>
              <li v-html="$t('pricing.premium.f1')"></li>
              <li v-html="$t('pricing.premium.f2')"></li>
              <li v-html="$t('pricing.premium.f12')"></li>
              <li v-html="$t('pricing.premium.f3')"></li>
              <li v-html="$t('pricing.premium.f10')"></li>
              <li v-html="$t('pricing.premium.f4')"></li>
              <li v-html="$t('pricing.premium.f5')"></li>
              <li v-html="$t('pricing.premium.f6')"></li>
              <li v-html="$t('pricing.premium.f7')"></li>
              <li v-html="$t('pricing.premium.f11')"></li>
              <li v-html="$t('pricing.premium.f8')"></li>
              <li v-html="$t('pricing.premium.f9')"></li>
            </ul>
          </div>
          <div>
            <router-link :to="{ name: 'FeatureRedirect', params: { feature: 'multi-user' } }">
              <div class="addon">
                <div class="amount">{{ $t('pricing.multi-user.amount') }}</div>
                <div class="name">
                  <h4 style="line-height: unset; margin: 0; font-size: 20px; font-weight: 700; text-align: center">{{ $t('pricing.multi-user.name') }}</h4>
                </div>
              </div>
            </router-link>
            <div hidden class="addon">
              <div class="amount">{{ $t('pricing.channels.amount') }}</div>
              <div class="name">
                <h4 style="line-height: unset; margin: 0; font-size: 20px; font-weight: 700; text-align: center">{{ $t('pricing.channels.name') }}</h4>
              </div>
              <div style="margin-top: 10px">
                <ul class="channels">
                  <li>
                    <img src="../../assets/WhatsAppBusiness_icon.png" height="35" width="35" alt="WhatsApp Business" />
                    <h4>{{ $t('pricing.channels.wa-business') }}</h4>
                  </li>
                  <li>
                    <img src="../../assets/WhatsAppAPI_icon.png" height="35" width="35" alt="WhatsApp API" />
                    <h4>{{ $t('pricing.channels.wa-api') }}</h4>
                  </li>
                  <li>
                    <img src="../../assets/Instagram_logo_2022.svg" height="35" width="35" alt="WhatsApp API" />
                    <h4>{{ $t('pricing.channels.instagram') }}</h4>
                  </li>
                  <li>
                    <img src="../../assets/fbmessenger-logo.png" height="35" width="35" alt="WhatsApp API" />
                    <h4>{{ $t('pricing.channels.facebook') }}</h4>
                  </li>
                </ul>
              </div>
            </div>
            <router-link :to="{ name: 'FeatureRedirect', params: { feature: 'ai' } }">
              <div class="addon">
                <div class="amount">{{ $t('pricing.ai.amount') }}</div>
                <div class="name">
                  <h4 style="line-height: unset; margin: 0; font-size: 20px; font-weight: 700; text-align: center">{{ $t('pricing.ai.name') }}</h4>
                </div>
                <div class="text">
                  {{ $t('pricing.ai.text') }}
                  <div>
                    <sub>{{ $t('pricing.ai.subtext') }}</sub>
                  </div>
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'FeatureRedirect', params: { feature: 'zadarma' } }">
              <div class="addon">
                <div class="amount">{{ $t('pricing.zadarma.amount') }}</div>
                <div class="name">
                  <h4 style="line-height: unset; margin: 0; font-size: 20px; font-weight: 700; text-align: center">{{ $t('pricing.zadarma.name') }}</h4>
                </div>
                <div class="text">
                  {{ $t('pricing.zadarma.text') }}
                  <div>
                    <sub>{{ $t('pricing.zadarma.subtext') }}</sub>
                  </div>
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'FeatureRedirect', params: { feature: 'emailbox' } }">
              <div class="addon addon-last">
                <div class="amount">{{ $t('pricing.emailbox.amount') }}</div>
                <div class="name">
                  <h4 style="line-height: unset; margin: 0; font-size: 20px; font-weight: 700; text-align: center">{{ $t('pricing.emailbox.name') }}</h4>
                </div>
                <div class="text">
                  {{ $t('pricing.emailbox.text') }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="organization" style="margin-bottom: 20px">
          <a :href="this.link">
            <button class="button button-pay">{{ $t('pricing.activate_now') }}</button>
          </a>
        </div>
        <div v-if="!organization" class="top-separate" style="margin-bottom: 20px">
          <router-link :to="{ name: 'Register'}">
            <button class="button button-pay">{{ $t('pricing.cta') }}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Pricing',
  computed: mapGetters(['organization']),
  data () {
    return {
      link: null
    }
  },
  methods: {
    ...mapActions(['fetchCheckoutSessionLink']),
    mustShow () {
      if (['Pricing'].includes(this.$route.name)) {
        this.emitter.emit('closeModulePopup')
        this.emitter.emit('openModuleIntegrationPopup')

        if (this.organization) {
          this.fetchCheckoutSessionLink({ credits: null }).then((link) => {
            this.link = link
          })
        }

        return true
      }

      return false
    },
    isUnpaid () {
      if (!this.organization) {
        return false
      }

      if (this.organization.unpaid) {
        return true
      }

      return false
    },
    close () {
      if (window.history.length > 1 &&
        document.referrer.indexOf(window.location.host) !== -1) {
        this.$router.back()
      } else {
        this.$router.push({ name: 'Home' })
      }
    },
    getTrialDaysRemaining () {
      const trialEndDate = new Date(this.organization.createdAt)
      trialEndDate.setDate(trialEndDate.getDate() + 7)

      const remainingDays = Math.round((trialEndDate - new Date()) / (1000 * 60 * 60 * 24))
      return remainingDays >= 0 ? remainingDays : 0
    }
  }
}
</script>

<style scoped>
.overlay {
  background-color: rgba(32,33,36,0.3) !important;
  opacity: 1 !important;
  z-index: 1000000 !important;
}

.modal {
  z-index: 1000001;
  background-color: #ffffff;
  position: fixed;
  top: 20px;
  left: 50%;
  border-radius: 8px 8px 0 0;
  margin-left: -445px;
  width: 890px;
  height: calc(100% - 40px);
  padding: 20px 20px 0 20px;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 4px 14px 0 rgba(0,0,0,.2);
}

.modal h3 {
  font-size: 20px;
  margin-top: 10px;
}

.prices {
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.prices .price {
  padding: 18px 20px;
  border: 1px solid;
  border-radius: 10px;
  text-align: left;
  width: 280px;
  position: relative;
}

.prices .price .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;
}

.prices .arrow {
  width: 80px;
  font-size: 35px;
  margin-top: 210px;
  color: #404040;
}

.prices ul {
  margin: 0;
  padding: 0;
}

.prices ul li {
  margin: 10px 0;
}

.price.free {
  border-color: #EDEFF1;
  color: #757575;
}

.price.premium {
  background: -webkit-gradient(linear, left top, left bottom, from(#3056d3), to(#179bee));
  background: linear-gradient(180deg, #3056d3 0%, #179bee 100%);
  border: 2px solid #d4deff;
  color: #ffffff;
}

.price li.amount {
  margin-bottom: 25px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.price.free .amount span {
  font-size: 13px;
  background-color: #5e5e5e;
  color: white;
  font-weight: 700;
  border-radius: 30px;
  padding: 5px 15px;
}

.price.premium .amount span {
  font-size: 13px;
  background-color: #13c296;
  font-weight: 700;
  border-radius: 30px;
  padding: 5px 15px;
}

.top-separate {
  margin-top: 25px;
}

.button {
  background-color: #ffffff;
  color: #4285f4;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  position: relative;
  margin-top: 12px;
  width: 100%;
  top: 0;
}

.button-pay {
  position: relative;
  width: calc(100% - 35px);
  background-color: #25D366;
  bottom: unset;
  color: white;
  margin-top: 0;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
}

.button-pay:hover {
  background-color: #13c296;
}

.addon {
  padding: 18px 20px;
  border: 2px solid #d4deff;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  width: 430px;
}

.addon.addon-last {
  margin-bottom: 0;
}

.addon .name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addon .name h4, .addon ul li h4 {
  margin: 0;
  line-height: 35px;
}

.addon .amount {
  position: absolute;
  top: 23px;
  right: 20px;
  font-size: 13px;
  color: #4285f4;
  font-weight: 700;
}

.addon img {
  margin-right: 10px;
}

.addon ul {
  margin: 0;
  padding: 0;
}

.addon ul li {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addon .text {
  text-align: left;
  margin-top: 15px;
}

.channels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.channels li {
  width: 50%;
}

a {
  text-decoration: none;
  color: inherit;
}

</style>
