<template>
  <div class="settings-group">
    <h3>{{ $t('settings.developers.subtitle') }}</h3>

    <div class="form-group">
      <h3>{{ $t('settings.developers.api.title') }}</h3>
      <p v-html="$t('settings.developers.api.description')"></p>
    </div>
    <div v-if="organization" class="form-group-columns">
      <div class="form-group" style="width: 75%">
        <label for="apikey">{{ $t('settings.developers.api.apikey.title') }}</label>
        <input readonly v-model="organization.apikey" type="text" id="apikey" name="apikey">
      </div>
      <div class="form-group" style="width: 25%">
        <button :disabled="organization && organization.watype === null" @click="onClickRegenerate" class="button" type="submit">{{ $t('settings.developers.api.apikey.regenerate') }}</button>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <h3>{{ $t('settings.developers.webhooks.title') }}</h3>
      <p v-html="$t('settings.developers.webhooks.description')"></p>
    </div>

    <br>

    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.developers.webhooks.forwards.title') }}</h4>
      <p v-html="$t('settings.developers.webhooks.forwards.description')"></p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'webhooksForward')" v-model:checked="organization.webhooksForward" />
      <input type="text" @input="onChangeValue" v-if="this.organization.webhooksForward" v-model="organization.webhooksForwardText" :placeholder="$t('settings.developers.webhooks.forwards.placeholder')">
    </div>
  </div>
</template>

<script>
import Switch from '../../../common/Switch'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionDevelopers',
  components: {
    Switch
  },
  data () {
    return {
      timer: null
    }
  },
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions([
      'fetchOrganization', 'updateOrganization', 'regenerateApikey',
      'getSupportContact', 'fetchConversation', 'fetchContacts'
    ]),
    onClickSupport () {
      this.getSupportContact().then(contact => {
        this.fetchContacts({ filter: 1 }).then(() => {
          this.fetchConversation({ contact: contact, messageId: null })
        })
      })
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    onChangeSwitch (value, key) {
      this.organization[key] = value

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onChangeValue (event) {
      clearTimeout(this.timer)

      if (event.currentTarget.value === '' || event.currentTarget.value === '0') {
        return
      }

      this.timer = setTimeout(() => {
        let url
        try {
          url = new URL(this.organization.webhooksForwardText)
        } catch (_) {
          url = null
        }

        if (url === null || url.protocol !== 'https:') {
          this.$toast.open({
            message: this.$t('settings.webhooks.errors.invalid_url'),
            type: 'error'
          })

          return
        }

        this.updateOrganization(this.organization).then(() => {
          this.$toast.open({
            message: this.$t('form.saved')
          })
        })
      }, 1000)
    },
    onClickRegenerate () {
      this.regenerateApikey()
    }
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization()
    }
  }

}
</script>

<style scoped>
h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.form-group-columns {
  display: flex;
}

.form-group-columns .form-group {
  padding: 0 5px;
}
</style>

<style>
.wabusiness-overlay {
  position: absolute;
  background-color: rgba(255,255,255,0.8);
  width: 100%;
  height: 100%;
  z-index: 100000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.wabusiness-restricted {
  margin-top: 20px;
  padding: 20px 20px 0 20px;
  background-color: #4285f45e;
  border-radius: 8px;
  z-index: 200000;
  margin-right: 30px;
  margin-bottom: 20px;
}

.wabusiness-restricted .button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  width: 260px;
  position: relative;
  margin-top: 20px
}

.wabusiness-restricted .button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.wabusiness-restricted .button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}
</style>
