<template>
  <div>
    <Alerts />

    <Navbar />
    <div class="content" :class="{ 'hideSidebarChats': [4,6,7].includes(this.filter) }"> <!-- 6 is CALENDAR, 7 is INTERNAL CHATS -->
      <Sidebar />
      <SidebarChats v-if="![4,6,7].includes(this.filter)" />
      <Content />
      <Crm />
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Navbar from '../components/home/Navbar'
import Sidebar from '../components/home/Sidebar'
import Content from '../components/home/Content'
import Crm from '../components/home/Crm'
import NewMessage from '../components/modals/NewMessage'
import MobileSidebar from '../components/mobile/Sidebar'

import SidebarChats from '@/components/home/chat/SidebarChats'
import Alerts from '@/components/home/alerts/Alerts.vue'

export default {
  name: 'Chats',
  methods: {
    ...mapActions(['setFilterView', 'setSection', 'setPage'])
  },
  props: {
    filterView: Number,
    page: Number
  },
  computed: mapGetters(['newMessageOpen', 'filter', 'loggedUser']),
  created () {
    this.setSection(null)

    if (this.filter === 1 && !this.loggedUser.canSeeAllChats) {
      return
    }

    if (undefined !== this.filterView && this.filter == null) {
      this.setFilterView(this.filterView)
    } else if (undefined !== this.$route.params.filterView && this.filter == null) {
      this.setFilterView(this.$route.params.filterView)
    }

    if (undefined !== this.page && this.page == null) {
      this.setPage(this.page)
    }
  },
  components: {
    Alerts,
    SidebarChats,
    Navbar,
    Sidebar,
    Content,
    Crm,
    NewMessage,
    MobileSidebar
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.anyAlert .content {
  margin-top: 127px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
