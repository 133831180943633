<template>
  <div class="overlay" v-if="mustShow()">
    <div class="modal">
      <div class="wrapper">
        <div class="header">
          <h3 class="title">{{ $t('register.connector.title') }}</h3>
        </div>
        <div class="left">
          <div>
            <div class="whatsapp-kind">
              <img src="../../../assets/WhatsAppBusiness_icon.png" height="35" width="35" alt="WhatsApp Business" /> <h4>{{ $t('register.connector.business.title') }}</h4>
            </div>
          </div>
          <div class="pros-cons">
            <div class="pros">
              <ul>
                <li>{{ $t('register.connector.business.pros.1') }}</li>
                <li>{{ $t('register.connector.business.pros.2') }}</li>
              </ul>
            </div>
            <div class="cons">
              <ul>
                <li>{{ $t('register.connector.business.cons.1') }}</li>
                <li>{{ $t('register.connector.business.cons.2') }}</li>
                <li>{{ $t('register.connector.business.cons.3') }}</li>
              </ul>
            </div>
          </div>
          <div class="buttons-connector">
            <button :disabled="loading" class="button button-connector" @click="onClickWhatsAppBusiness()">{{ $t('register.connector.business.button') }}</button>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="whatsapp-kind">
              <img src="../../../assets/WhatsAppAPI_icon.png" height="35" width="35" alt="WhatsApp API" /> <h4>{{ $t('register.connector.api.title') }}</h4>
            </div>
          </div>
          <div class="pros-cons">
            <div class="pros">
              <ul>
                <li>{{ $t('register.connector.api.pros.1') }}</li>
                <li>{{ $t('register.connector.api.pros.2') }}</li>
                <li>{{ $t('register.connector.api.pros.3') }}</li>
              </ul>
            </div>
            <div class="cons">
              <ul>
                <li v-html="$t('register.connector.api.cons.1')"></li>
                <li>{{ $t('register.connector.api.cons.2') }}</li>
               </ul>
            </div>
          </div>
          <div class="buttons-connector">
            <button :disabled="loading" class="button button-connector" @click="onClickWhatsAppAPI()">{{ $t('register.connector.api.button') }}</button>
            <button :disabled="loading" class="button button-light button-connector" @click="onClickSkip()">{{ $t('register.connector.api.skip') }}</button>
          </div>
        </div>
        <hr>
        <button :disabled="loading" class="button button-light" @click="onClickSkip()">{{ $t('onboarding.continue') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'ConnectorSelect',
  computed: mapGetters([]),
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['getNewQR', 'saveFacebookAPI', 'fetchOrganization', 'fetchBusinessAccount']),
    mustShow () {
      if (['ConnectorSelect'].includes(this.$route.name)) {
        return true
      }

      return false
    },
    onClickSkip () {
      const sessionToken = sessionStorage.getItem('accessTokensSession')
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      Cookies.set('accessToken', sessionToken, { expires: 7, path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

      if (window.location &&
        window.location.host &&
        window.location.host === 'www.manycontacts.com') {
        sessionStorage.removeItem('accessTokensSession')
        localStorage.removeItem('accessTokens')
      }

      window.location.href = '/'
    },
    onClickWhatsAppBusiness () {
      this.loading = true

      this.getNewQR().then(() => {
        this.$router.push({ name: 'ConnectorWhatsAppBusiness' })
      }).catch(() => {
        this.loading = false

        this.$toast.open({
          message: this.$t('pages.register.error.generic'),
          type: 'error'
        })
      })
    },
    onClickWhatsAppAPI () {
      const FB = window.FB
      const self = this
      FB.login(function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code
          self.saveFacebookAPI(code).then((response) => {
            if (!response.data) {
              self.$toast.open({
                message: self.$t('settings.integrations.api_facebook.error'),
                type: 'error'
              })
              self.emitter.emit('closeMetaCloudPopupOpened')

              return
            }

            if (response.data.finalized === true) {
              self.$toast.open({
                message: self.$t('form.saved')
              })
              self.fetchOrganization().then(() => {
                self.fetchBusinessAccount().then((response) => {
                  self.businessAccount = response
                  self.emitter.emit('closeMetaCloudPopupOpened')

                  self.$router.push({ name: 'ConnectorWhatsAppAPIPaymentMethod' })
                })
              })
              return
            }

            if (response.data.finalized === false) {
              self.emitter.emit('closeMetaCloudPopupOpened')
              self.emitter.emit('openMetaCloudSelectNumber', response.data.listNumbers)
            }
          }).catch(() => {
            console.log('catch')
            self.$toast.open({
              message: self.$t('settings.integrations.api_facebook.error'),
              type: 'error'
            })

            self.emitter.emit('closeMetaCloudPopupOpened')
          })
        } else {
          self.$toast.open({
            message: self.$t('onboarding.error'),
            type: 'error'
          })
          self.emitter.emit('closeMetaCloudPopupOpened')
        }
      }, {
        config_id: process.env.VUE_APP_FACEBOOK_APP_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true
      })

      this.emitter.emit('openMetaCloudPopupOpened')
    }
  },
  mounted () {
    window.fbAsyncInit = function () {
      const FB = window.FB
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      })
    };

    (function (d, s, id) {
      var js; var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  }
}
</script>

<style scoped>

.overlay {
  background-color: rgb(17, 27, 33, 0.3) !important;
  opacity: 1 !important;
  z-index: 1000000 !important;
}

.modal {
  height: 490px;
  width: 830px;
  margin-left: -415px;
  left: 50%;
  top: 175px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  border-radius: 10px;
}

.mobile .modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

h3 {
  font-size: 24px;
  color: #25D366;
}

.header {
  width: 100%;
  text-align: center;
  height: 50px;
}

.header h3 {
  line-height: 40px;
  margin-top: 5px;
  margin-bottom: 0;
}

.left {
  position: relative;
  text-align: center;
  width: calc(50% - 20px);
  padding: 10px;
  height: calc(100% - 115px);
  border-right: 1px solid #25D366;
}

.right {
  position: relative;
  text-align: center;
  width: calc(50% - 20px - 1px);
  padding: 10px;
  height: calc(100% - 115px);
}

.button {
  width: 100%;
  bottom: 10px;
  background-color: #25D366;
}

.buttons-connector {
  margin-top: 25px;
}

.button.button-connector {
  background-color: #25D366;
  position: relative;
  top: unset;
  margin-top: 10px;
  width: fit-content;
  padding: 0 30px;
}

.button.button-light {
  color: #25D366;
  background-color: transparent;
}

.button:disabled {
  background-color: #25d36682;
}

.whatsapp-kind {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.whatsapp-kind h4 {
  padding: 0;
  line-height: 35px;
  margin: 0 0 0 10px;
}

.instructions-qr {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 24px;
}

.instructions-qr ol {
  text-align: left;
  margin: -4px;
}

.instructions-qr ol li {
  margin: 10px 0;
}

.instructions-api button {
  position: relative;
  width: fit-content;
  padding: 0 30px;
}

.pros-cons {
  margin-top: 25px;
  width: 100%;
}

.pros-cons ul {
  margin: 0;
  padding: 10px 0;
}

.pros-cons ul li {
  margin: 5px 0;
}

.pros {
  background-color: #E8FCDC;
}

.cons {
  margin-top: 10px;
  background-color: #FCE8E8;
}

.step1 .right .button {
  position: relative;
  width: 100%;
  bottom: unset;
  margin-bottom: 20px;
}

.wrapper {
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  overflow: hidden;
}

</style>
