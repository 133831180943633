<template>
  <aside style="padding-top: 20px;">
    <b style="margin-left: 20px; margin-top: 0px;">{{ $t('sidebar.inbox') }}</b>
    <ul style="margin-top: 10px;" class="main-menu">
      <router-link to="/chats" class="chats-unassigned" @click="saveChatsUrl('/chats', 0, null); onClickFetchContacts(0)" v-tooltip.right="{ content: $t('sidebar.chats_unassigned') }">
        <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 0 ? 'active' : '']">
          <div class="sidebar-icon"><i class="far fa-comment"></i></div>
          <span>{{ $t('sidebar.chats_unassigned') }}</span>
          <div v-if="counters && counters.unassigned > 0 && counters.unassigned <= 99" class="unread-badge unread-badge-important">{{ counters.unassigned }}</div>
          <div v-if="counters && counters.unassigned > 0 && counters.unassigned > 99" class="unread-badge unread-badge-important">+99</div>
        </li>
      </router-link>
      <router-link v-if="loggedUser && loggedUser.canSeeAllChats" to="/chats/all" @click="saveChatsUrl('/chats/all', 1, null); onClickFetchContacts(1)" v-tooltip.right="{ content: $t('sidebar.chats_all') }">
        <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 1 ? 'active' : '']">
          <div class="sidebar-icon"><i class="far fa-comments"></i></div>
          <span>{{ $t('sidebar.chats_all') }}</span>
          <div v-if="counters && counters.all > 0 && counters.all <= 99" class="unread-badge">{{ counters.all }}</div>
          <div v-if="counters && counters.all > 0 && counters.all > 99" class="unread-badge">+99</div>
        </li>
      </router-link>
      <router-link to="/chats/my-chats" @click="saveChatsUrl('/chats/my-chats', 2, null); onClickFetchContacts(2)" v-tooltip.right="{ content: $t('sidebar.chats_my_chats') }">
        <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 2 ? 'active' : '']">
          <div class="sidebar-icon"><i class="far fa-comment-dots"></i></div>
          <span>{{ $t('sidebar.chats_my_chats') }}</span>
          <div v-if="loggedUser && counters && counters.assigned[loggedUser.id] > 0 && counters.assigned[loggedUser.id] <= 99" class="unread-badge">{{ counters.assigned[loggedUser.id] }}</div>
          <div v-if="loggedUser && counters && counters.assigned[loggedUser.id] > 0 && counters.assigned[loggedUser.id] > 99" class="unread-badge">+99</div>
        </li>
      </router-link>
      <router-link to="/chats/starred" @click="saveChatsUrl('/chats/starred', 3, null); onClickFetchContacts(3)" v-tooltip.right="{ content: $t('sidebar.chats_starred') }">
        <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 3 ? 'active' : '']">
          <div class="sidebar-icon"><i class="far fa-star"></i></div>
          <span>{{ $t('sidebar.chats_starred') }}</span>
          <div v-if="loggedUser && counters && counters.starred[loggedUser.id] > 0 && counters.starred[loggedUser.id] <= 99" class="unread-badge">{{ counters.starred[loggedUser.id] }}</div>
          <div v-if="loggedUser && counters && counters.starred[loggedUser.id] > 0 && counters.starred[loggedUser.id] > 99" class="unread-badge">+99</div>
        </li>
      </router-link>
    </ul>
    <div class="scrolleable">
      <div v-if="loggedUser && teams && teams.length > 0 && loggedUser.teams && loggedUser.teams.length > 0">
        <b style="margin-left: 20px;">{{ $t('settings.teams.title') }}</b>
        <ul style="margin-top: 10px;">
          <router-link :key="team.id" v-for="team in loggedUser.teams" :team="team" :to="'/chats/team/' + team.id" @click="saveChatsUrl('/chats/team/' + team.id, null, team.id); onClickFetchTeamContacts(team)" v-tooltip.right="getTeamName(team)">
            <li class="team" :class="[this.filter === team.id ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-users"></i></div>
              <div class="team-name">{{ getTeamName(team) }}</div>
              <div v-if="counters && counters.teams[team.id] > 0 && counters.teams[team.id] <= 99" class="unread-badge">{{ counters.teams[team.id] }}</div>
              <div v-if="counters && counters.teams[team.id] > 0 && counters.teams[team.id] > 99" class="unread-badge">+99</div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SidebarChats',
  computed: mapGetters(['counters', 'loggedUser', 'filter', 'section', 'locale',
    'teams', 'organization', 'allContacts', 'page', 'filtersOpen'
  ]),
  methods: {
    ...mapActions([
      'fetchContacts',
      'toggleNewMessage',
      'clearSearch',
      'setPage',
      'setFilterView',
      'fetchTeamContacts',
      'fetchConversation',
      'fetchContact'
    ]),
    getTeamName (team) {
      const found = this.teams.find(el => el.id === team.id)
      if (found) {
        return found.name
      }
      return null
    },
    onClickFetchContacts (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchContacts({ filter: filter })
    },
    onClickFetchTeamContacts (team) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)
      this.fetchTeamContacts(team.id)
    },
    saveChatsUrl (url, filter, team) {
      localStorage.chatsUrl = url
      localStorage.chatsFilter = filter
      localStorage.chatsTeam = team
    }
  }
}
</script>

<style scoped>
.scrolleable{
  height: calc(100vh - 290px);
  overflow-y: auto;
  margin-top: 40px;
}

  .mobile aside {
    display: none;
  }

  aside {
    top: 66px;
    left: 71px;
    padding-top: 10px;
    width: 200px;
    position: fixed;
    height: calc(100% - 66px);
    text-align: left;
    border-right: 1px solid #EDEFF1;
  }

  .anyAlert aside {
    top: 126px;
    height: calc(100% - 126px);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px 10px 0 0;
  }

  ul.top-separate {
    margin-top: 25px;
  }

  ul li {
    position: relative;
    padding: 5px 0px 5px 25px;
    -webkit-border-top-right-radius: 50px;
    -webkit-border-bottom-right-radius: 50px;
    -moz-border-radius-topright: 50px;
    -moz-border-radius-bottomright: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  ul li .sidebar-icon {
    width: 25px;
    float: left;
  }

  .sidebar-icon .tag{
    margin-left:4px;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    float: left;
    margin-top: 5px;
  }

  ul li .unread-badge {
    margin-right: 8px;
  }

  ul li:hover {
    background-color: #F1F3F4;
  }

  ul li.active {
    background-color: #4285f45e;
    color: #4285f4;
    font-weight: bold;
  }

  ul li.team .team-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 105px;
  }

  ul a {
    color: #111b21;
    text-decoration: none;
  }

  .unread-badge {
    box-sizing: border-box;
    min-width: 21px;
    border-radius: 20px;
    background-color: transparent;
    color: #4285f4;
    border: 1px solid #4285f4;
    font-weight: bold;
    text-align: center;
    float: right;
    font-size: 10px;
    height: 20px;
    line-height: 18px;
    padding: 0 1px;
    position: absolute;
    right: -3px;
    top: 4px;
  }

  .unread-badge.unread-badge-important {
    background-color: #4285f4;
    color: #ffffff;
  }
</style>
