<template>
  <nav>
   <div class="alert-wrapper">
     <div class="text" v-html="$t('connectors.alert.text')"></div>
     <div class="buttons">
<!--       <button :disabled="loading" @click="onClickButtonInstagram" class="button">{{ $t('connectors.alert.button.instagram') }}</button>-->
       <button :disabled="loading" @click="onClickButtonWhatsAppAPI($event)" class="button">{{ $t('connectors.alert.button.whatsapp-api') }}</button>
       <button :disabled="loading" @click="onClickButtonWhatsAppQR" class="button">{{ $t('connectors.alert.button.whatsapp-business') }}</button>
     </div>
   </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConnectorsAlert',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['getNewQR', 'saveFacebookAPI', 'fetchOrganization', 'fetchBusinessAccount']),
    onClickButtonWhatsAppQR () {
      this.loading = true
      this.getNewQR().then(() => {
        this.$router.push({ name: 'ConnectorWhatsAppBusiness' })
        this.loading = false
      }).catch(() => {
        this.loading = false

        this.$toast.open({
          message: this.$t('pages.register.error.generic'),
          type: 'error'
        })
      })
    },
    onClickButtonWhatsAppAPI () {
      const FB = window.FB
      const self = this
      FB.login(function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code
          self.saveFacebookAPI(code).then((response) => {
            if (!response.data) {
              self.$toast.open({
                message: self.$t('settings.integrations.api_facebook.error'),
                type: 'error'
              })
              self.emitter.emit('closeMetaCloudPopupOpened')

              return
            }

            if (response.data.finalized === true) {
              self.$toast.open({
                message: self.$t('form.saved')
              })
              self.fetchOrganization().then(() => {
                self.fetchBusinessAccount().then((response) => {
                  self.businessAccount = response
                  self.emitter.emit('closeMetaCloudPopupOpened')

                  self.$router.push({ name: 'ConnectorWhatsAppAPIPaymentMethod' })
                })
              })
              return
            }

            if (response.data.finalized === false) {
              self.emitter.emit('closeMetaCloudPopupOpened')
              self.emitter.emit('openMetaCloudSelectNumber', response.data.listNumbers)
            }
          }).catch(() => {
            console.log('catch')
            self.$toast.open({
              message: self.$t('settings.integrations.api_facebook.error'),
              type: 'error'
            })

            self.emitter.emit('closeMetaCloudPopupOpened')
          })
        } else {
          self.$toast.open({
            message: self.$t('onboarding.error'),
            type: 'error'
          })
          self.emitter.emit('closeMetaCloudPopupOpened')
        }
      }, {
        config_id: process.env.VUE_APP_FACEBOOK_APP_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true
      })

      this.emitter.emit('openMetaCloudPopupOpened')
    },
    onClickButtonInstagram () {
      // TODO: Implement when IG branch is merged
    }
  }
}
</script>

<style scoped>
nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #4285f45e;
  color: #4285f4;
  z-index: 4;
  text-align: center;
  display: none;
  height: 60px;
}

.connectorsAlert nav {
  display: flex;
}

.alert-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
}

.text {
  padding-top: 13px;
  font-weight: 600;
  text-align: left;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

button {
  position: relative;
  right: unset;
  top: unset;
  left: unset;
  bottom: unset;
  margin: 0 0 0 10px;
  padding: 0 10px;
  width: fit-content;
}

</style>
