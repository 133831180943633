<template>
  <div v-if="organization && selectedContact" class="content-panel">
    <Header />
    <Messages />
    <Input v-if="organization && organization.watype !== null && hasCredits()"/>
    <InputNoCredits v-if="!hasCredits()"/>
    <NewCalendarEvent />
  </div>
  <div v-if="!selectedContact && !loadingConversation" class="content-panel content-panel-empty">
    <div>{{ $t('messages.list_empty') }}</div>
  </div>
  <!-- !selectedContact && loadingConversation  && false-->
  <div v-if="!selectedContact && loadingConversation" class="content-panel">
    <div class="skeleton-contact ">
      <div class="skeleton-blink">
        <div class="skeleton-contact-avatar "></div>
        <div class="skeleton-contact-name"><span class="skeleton-box" style="width:100px;"></span></div>
      </div>
    </div>
    <div class="skeleton-conversation"></div>
    <div class="skeleton-controls" ></div>
  </div>
  <div v-if="!selectedContact && loadingConversation" class="skeleton-aside">
    <div style="margin: 20px" class="skeleton-blink">
      <span class="skeleton-box" style="width:100%;margin-bottom: 20px;"></span>
      <span class="skeleton-box" style="width:100%;margin-bottom: 20px;"></span>
      <span class="skeleton-box" style="width:100%;"></span>
    </div>
  </div>
</template>

<script>
import Header from './Header'
import Messages from './Messages'
import Input from './Input'
import NewCalendarEvent from '@/components/modals/NewCalendarEvent'

import { mapGetters } from 'vuex'
import InputNoCredits from '@/components/home/chat/InputNoCredits'

export default {
  name: 'Panel',
  computed: mapGetters(['selectedContact', 'organization', 'contactMessages', 'loadingConversation']),
  components: {
    InputNoCredits,
    Header,
    Messages,
    Input,
    NewCalendarEvent
  },
  methods: {
    hasCredits () {
      if (this.isLast24hMessage()) {
        return true
      }

      if (this.organization.watype !== 'api') {
        return true
      }

      if (!this.organization.features.credits) {
        return true
      }

      if (this.organization.watype === 'api' && this.organization.credits <= 0) {
        return false
      }

      return true
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    isLast24hMessage () {
      if (this.isWABusiness()) {
        return true
      }

      if (this.selectedContact.isInternalSupport === 1) {
        return true
      }

      if (this.selectedContact.user_id) {
        return true
      }

      if (!this.contactMessages || this.contactMessages.length === 0) {
        return false
      }

      return new Date(this.selectedContact.lastInbound).getTime() > (new Date().getTime() - (1 * 24 * 60 * 60 * 1000))
    }
  }
}
</script>

<style scoped>
.content-panel {
  height: calc(100vh - 67px);
  width: calc(100% - 401px);
  margin-left: 330px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.anyAlert .content-panel {
  height: calc(100vh - 127px);
}

.content-panel-empty {
  text-align: center;
  padding-top: 100px;
}

.mobile .content-panel {
  width: 100%;
  margin-left: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  height: 100%;
}

.mobile .content-panel-empty {
  display: none;
}
.skeleton-box {
  display: inline-block;
  height: .8em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  border-radius: 3px;
}
.skeleton-blink {
  animation: blink 2s linear infinite;
}

.skeleton-contact {
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  background-color: #f2f5f5;
  border-bottom: 1px solid #e3e8ea;
  z-index: 1;
  overflow: hidden;
}
.skeleton-contact-avatar {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 40px;
  height: 40px;
  background: url(../../../assets/individual.png) no-repeat center / contain;
  background-size: 35px;
  cursor:pointer;
}
.skeleton-contact-name {
  position: absolute;
  top: 5px;
  left: 56px;
  width: calc(100% - 175px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.skeleton-conversation{
  height: calc(100% - 50px);
  position: relative;
  z-index: 0;
  background-color: rgba(240, 224, 199, .38);
  background-image:url(../../../assets/whatsapp-bg.png);
  background-repeat: repeat;
  background-size: 412.5px 749.25px;
}
.skeleton-controls {
  position: absolute;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  background-color: #f2f5f5;
  border-top: 1px solid #e3e8ea;
}

.skeleton-aside {
  top: 67px;
  right: 0;
  width: 250px;
  background: white;
  position: fixed;
  border-left: 1px solid #EDEFF1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 67px);
}
</style>
