<template>
    <div v-if="this.organization" class="module" @click="onClickModule">
      <h3>{{ $t('modules.trial.title') }} <span v-if="getTrialDaysRemaining() > 2">{{ $t('modules.trial.in') }}</span></h3>
      <h1 v-if="getTrialDaysRemaining() > 2">{{ getTrialDaysRemaining() }} {{ $t('modules.trial.days') }}</h1>
      <h1 v-if="getTrialDaysRemaining() === 2">{{ $t('modules.trial.tomorrow') }}</h1>
      <h1 v-if="getTrialDaysRemaining() <= 1">{{ $t('modules.trial.today') }}</h1>
      <img v-if="getTrialDaysRemaining() >= 7" class="marker" src="../../../assets/marker.svg">
      <img v-if="getTrialDaysRemaining() < 7" class="marker" src="../../../assets/marker_red.svg">
      <button>{{ $t('modules.trial.upgrade') }}</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TrialModule',
  computed: mapGetters(['organization']),
  methods: {
    onClickModule () {
      this.$router.push({ name: 'Pricing' })
    },
    getTrialDaysRemaining () {
      const trialEndDate = new Date(this.organization.createdAt)
      trialEndDate.setDate(trialEndDate.getDate() + 7)

      const remainingDays = Math.round((trialEndDate - new Date()) / (1000 * 60 * 60 * 24))
      return remainingDays >= 0 ? remainingDays : 0
    }
  },
  props: {
    module: Object
  }
}
</script>

<style scoped>
.module {
  position: relative;
  height: 235px;
  width: 360px;
  padding: 10px 20px;
  background: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  border-radius: 4px;
  cursor: pointer;
}

.module h3 {
  margin-top: 0;
  text-align: center
}

.module h1 {
  text-align: center;
  line-height: 133px;
  font-size: 40px;
}

.module img.marker {
  position: absolute;
  top: 40px;
  width: 220px;
  left: 90px;
}

button {
  width: calc(100% - 48px);
  height: 40px;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  color: #4285f4;
  border: 1px solid #4285f4;
  cursor: pointer;
}

button:hover {
  background-color: #4285f4;
  color: white;
}

</style>
