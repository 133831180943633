import axios from 'axios'

const state = {
  organization: null,
  organizationSchedule: null,
  users: null,
  usersToAssign: null,
  qrTemp: null,
  showTimer: false,
  billable: null
}
const getters = {
  organization: (state) => state.organization,
  organizationSchedule: (state) => state.organizationSchedule,
  organizationUsers: (state) => state.users,
  organizationUsersToAssign: (state) => state.usersToAssign,
  qrTemp: (state) => state.qrTemp,
  showTimer: (state) => state.showTimer,
  billable: (state) => state.billable
}
const actions = {
  async register ({ commit, rootState }, data) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/register`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      return res.data
    })
  },
  async saveLead ({ commit, rootState }, data) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/lead`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      return res.data
    })
  },
  async fetchOrganization ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setOrganization', res.data)

    return res.data
  },
  async updateOrganization ({ commit, dispatch, rootState }, organization) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/organization`, organization, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async updateOrganizationLogo ({ commit, dispatch, rootState }, data) {
    const formData = new FormData()
    formData.append('file', data)
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/organization/logo`, formData, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async fetchOrganizationUsers ({ commit, rootState }, translator) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/users`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setOrganizationUsers', res.data)

    const usersToAssign = Object.assign([], res.data)
    usersToAssign.unshift({
      id: 0,
      name: translator('contact.unassigned'),
      canSeeSettings: false,
      canSeeAllChats: false
    })

    usersToAssign.push({
      id: -1,
      name: translator('settings.permissions.invite_user'),
      canSeeSettings: false,
      canSeeAllChats: false
    })

    commit('setOrganizationUsersToAssign', usersToAssign)
  },
  async fetchOrganizationUsersByName ({ commit, rootState }, name) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/users?name=${name}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async fetchOrganizationSchedule ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/schedule`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setOrganizationSchedule', res.data)
  },
  async updateOrganizationSchedule ({ commit, dispatch, rootState }, schedule) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/organization/schedule`, {
      schedule: schedule
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      commit('setOrganizationSchedule', res.data)
    }).catch(() => {})
  },
  async getSupportContact ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/support`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async regenerateApikey ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/apikey`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setOrganizationApikey', res.data.apikey)
  },
  async newWabaRequest ({ commit, rootState }, data) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/organization/waba-request`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async getNewQR ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/qr/new`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setOrganizationApikey', res.data.apikey)
    return res.data.apikey
  },
  async deleteQr ({ commit, rootState }) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/qr`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async fetchCheckoutSessionLink ({ commit, rootState }, { credits, autoRecharge }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/checkout/session?credits=${credits}&autoRecharge=${autoRecharge}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data.link
  },
  async fetchCheckoutSessionLinkForModule ({ commit, rootState }, module) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/checkout/session/module/${module.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data.link
  },
  async fetchAddMethodLink ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/checkout/addmethod`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data.link
  },
  async saveStripePayment ({ commit, rootState }, sessionId) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/organization/checkout/session`,
      { sessionId: sessionId },
      {
        headers: {
          token: rootState.sessions.userIsAutenticate
        }
      })
  },
  async fetchBillable ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/billable`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setBillable', res.data)
  },

  async emptyAccount ({ commit, rootState }) {
    await axios.post(`${process.env.VUE_APP_SERVERHOST}api/organization/empty`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setContacts', [])
    commit('setCounters', null)
    commit('setSelectedContact', null)
  },
  SOCKET_newQR ({ commit, dispatch }, qr) {
    console.log('💚💚💚💚', qr)

    if (qr === null) {
      dispatch('fetchOrganization')
    }

    commit('setQRTemp', qr)
  },
  SOCKET_showTimer ({ commit }) {
    commit('setShowTimer', true)
  },
  SOCKET_credits ({ dispatch }, credits) {
    dispatch('fetchOrganization')
  }
}
const mutations = {
  setOrganization: (state, organization) => (state.organization = organization),
  setOrganizationSchedule: (state, organizationSchedule) => (state.organizationSchedule = organizationSchedule),
  setOrganizationUsers: (state, users) => (state.users = users),
  setOrganizationUsersToAssign: (state, usersToAssign) => (state.usersToAssign = usersToAssign),
  deleteOrganizationUser: (state, delUser) => {
    const index = state.users.findIndex(user => user.id === delUser.id)
    if (index !== -1) {
      state.users.splice(index, 1)
    }
  },
  setOrganizationApikey: (state, apikey) => (state.organization.apikey = apikey),
  setQRTemp: (state, qrTemp) => (state.qrTemp = qrTemp),
  setShowTimer: (state, showTimer) => (state.showTimer = showTimer),
  setBillable: (state, billable) => (state.billable = billable)
}

export default {
  state,
  getters,
  actions,
  mutations
}
