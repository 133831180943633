<template>
  <router-link :to="{ name: 'FeatureRedirectLocale', params: { feature: 'whatsapp-expert', locale: this.$i18n.locale }}">
    <h2 class="consulting">{{ $t('integrations.consulting.title') }}</h2>
    <div class="price">{{ $t('integrations.consulting.price') }}</div>
    <div class="consulting-img">
      <img src="@/assets/nico.png" alt="test">
    </div>
    <div class="chat-thread">
      <div class="message received" >
        <span class="data">
          <span class="text">{{ $t('integrations.consulting.message1') }}</span>
        </span>
        <div class="metadata" >
          <span class="time">{{getCurrentTime()}}</span>
        </div>
      </div>
      <div class="message received">
        <span class="data">
          <span class="text">{{ $t('integrations.consulting.message2') }}</span>
        </span>
        <div class="metadata">
          <span class="time">{{getCurrentTime(1)}}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'consulting',
  methods: {
    getCurrentTime (addedMinute = 0) {
      const curDate = new Date()
      curDate.setMinutes(curDate.getMinutes() + addedMinute)
      return curDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
h2.consulting {
  position: absolute;
  top: 0;
  right: 20px;
}
.price {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}
.consulting-img {
  position: relative;
  top: -10px;
  left: -10px;
  z-index: 1;
}
.consulting-img img {
  width: 120px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
}

/*  CHAT  */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.chat-thread {
  margin-left: 60px;
}

.message {
  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 0 0 5px 0;
  word-wrap: break-word;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
  animation: slide-in-right 0.5s ease-in-out both;
}
.message:nth-child(2) {
  animation-delay: 2s;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.received {
  background: #d9fdd3;
  border-radius: 0 7.5px 7.5px 7.5px;
  width: fit-content;
}

.received .metadata {
  padding: 0 0 0 16px;
}

.received:after {
  border-width: 0 10px 10px 0;
  border-color: transparent #e1ffc7 transparent transparent;
  top: 0;
  left: -10px;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}
.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

</style>
