<template>
  <div class="overlay" v-if="mustShow()">
    <div class="modal">
      <div class="wrapper">
        <div class="left step2" :class="{ es: this.locale === 'es'}">
          <img class="meta-business-partner" src="../../../assets/meta-business-partner-badge-dark-1.webp" />
        </div>
        <div class="right">
          <div>
            <h3>{{ $t('register.payment_method.title') }}</h3>
            <p class="qr-subtitle" v-html="$t('register.payment_method.subtitle')"></p>

            <button class="button" @click="onClickSkip()" style="position: relative; width: 100%; bottom: unset; margin-top: 20px;">{{ $t('register.payment_method.button') }} <i class="fas fa-external-link-alt"></i></button>

            <button class="button button-light" style="bottom: 10px; left: 0; width: 100%;" @click="onClickSkip()">{{ $t('register.payment_method.skip') }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'ConnectorWhatsAppAPIPaymentMethod',
  methods: {
    mustShow () {
      if (['ConnectorWhatsAppAPIPaymentMethod'].includes(this.$route.name)) {
        return true
      }

      return false
    },
    onClickSkip () {
      const sessionToken = sessionStorage.getItem('accessTokensSession')
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      Cookies.set('accessToken', sessionToken, { expires: 7, path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

      if (window.location &&
          window.location.host &&
          window.location.host === 'www.manycontacts.com') {
        sessionStorage.removeItem('accessTokensSession')
        localStorage.removeItem('accessTokens')
      }

      window.location.href = '/'
    }
  }
}
</script>

<style scoped>

.overlay {
  background-color: rgb(17, 27, 33, 0.3) !important;
  opacity: 1 !important;
  z-index: 1000000 !important;
}

.modal {
  height: 490px;
  margin-left: -415px;
  left: 50%;
  top: 175px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: fit-content;
  padding: 0px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  border-radius: 10px;
}

.mobile .modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.mobile .modal .left {
  display: none;
}

.mobile .modal .right {
  width: 100%;
}

.phone-group {
  display: none;
}

.mobile .phone-group {
  display: block;
}

.number-of-users {
  display: flex;
}

h3 {
  font-size: 24px;
  color: #25D366;
}

.left {
  width: 450px;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.left.step2 {
  background-image: url("../../../assets/perro1.jpeg");
  background-position: right top;
}

.right {
  width: 370px;
  padding: 15px;
  position: relative;
  height: calc(100% - 30px);
}

.button {
  width: calc(100% - 30px);
  background-color: #25D366;
}

.button.button-light {
  color: #25D366;
  background-color: transparent;
}

.button:disabled {
  background-color: #25d36682;
}

.wrapper {
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  display: flex;
  overflow: hidden;
}

.number-of-users .users-selector {
  width: 33%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
}

.number-of-users .users-selector:hover {
  background-color: #ffffff;

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector.active {
  background-color: rgba(51, 75, 250, 0.07);

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector:first-of-type {
  margin-left: 0;
}

.number-of-users .users-selector:last-of-type {
  margin-right: 0;
}

.language-selector {
  position: absolute;
  top: 55px;
  right: 15px;
}

.step2-mobile div {
  font-size: 20px;
  margin: 20px 0;
}

</style>

<style>
.qr-subtitle {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.qr-subtitle ul {
  margin: 0;
  padding: 0;
}

.qr-subtitle ul li {
  margin-bottom: 15px;
}

.vue-tel-input input {
  border: 0;
}

.meta-business-partner {
  position: absolute;
  bottom: 12px;
  left: 2px;
  width: 170px;
}
</style>
