<template>
<!--  <div v-if="isWABusiness()" class="wabusiness-restricted">-->
<!--    {{ $t('wabusiness.restricted.mobile') }}-->
<!--    <br>-->
<!--    {{ $t('wabusiness.restricted.help') }}-->
<!--    <br><br>-->
<!--    <router-link to="/chats" @click="onClickSupport">-->
<!--      <button class="button" >{{ $t('wabusiness.restricted.contact') }}</button>-->
<!--    </router-link>-->
<!--  </div>-->
  <div class="settings-group">
    <h3>{{ $t('settings.auto_reply.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.auto_reply.description') }}</p>
    </div>

    <br>

    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.auto_reply.welcome.title') }}</h4>
      <p>{{ $t('settings.auto_reply.welcome.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoReplyOpen')" v-model:checked="organization.autoReplyOpen" />
      <div class="welcome-radios" v-if="organization.autoReplyOpen && organization.watype === 'api'">
        <input @change="onChangeValue" type="radio" id="text" value="text" v-model="organization.autoReplyOpenType">
        <label for="text">{{ $t('settings.auto_reply.welcome.text') }}</label>
        <input @change="onChangeValue" type="radio" id="template" value="template" v-model="organization.autoReplyOpenType">
        <label for="template">{{ $t('settings.auto_reply.welcome.template') }}</label>
      </div>
      <textarea v-if="organization.autoReplyOpen && organization.autoReplyOpenType === 'text'" @input="onChangeValue" v-model="organization.autoReplyOpenText" :placeholder="$t('settings.auto_reply.welcome.placeholder')"></textarea>
      <select @change="onChangeValue" style="width: 100%" v-if="organization.autoReplyOpen && organization.autoReplyOpenType === 'template' && templates" v-model="this.organization.autoReplyOpenTemplateId">
        <option v-for="template in templates" :value="template.id" :key="template.id" :template="template">
          {{ template.label }}
        </option>
      </select>
    </div>
    <hr>
    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.auto_reply.close.title') }}</h4>
      <p>{{ $t('settings.auto_reply.close.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoReplyClose')" v-model:checked="organization.autoReplyClose" />
      <textarea @input="onChangeValue" v-if="organization.autoReplyClose" v-model="organization.autoReplyCloseText" :placeholder="$t('settings.auto_reply.close.placeholder')"></textarea>
      <div v-if="organization.autoReplyClose" class="form-group" style="margin-top: 10px">
        <label for="close-minutes">{{ $t('settings.auto_reply.close.message_after') }}</label>
        <input @input="onChangeValue" type="email" id="close-minutes" name="close-minutes" v-model="organization.autoReplyCloseMinutes">
        <span>{{ $t('settings.auto_reply.close.minutes') }}</span>
      </div>
    </div>
    <hr>
    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.auto_reply.away.title') }}</h4>
      <p>{{ $t('settings.auto_reply.away.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoReplyAway')" v-model:checked="organization.autoReplyAway" />
      <textarea @input="onChangeValue" v-if="organization.autoReplyAway" v-model="organization.autoReplyAwayText" :placeholder="$t('settings.auto_reply.away.placeholder')"></textarea>
      <div v-if="organization.autoReplyAway" class="form-group" style="margin-top: 10px">
        <label>{{ $t('settings.auto_reply.away.timezone') }}</label>
        <select @change="onChangeValue" v-model="organization.timezone">
          <option value='Etc/GMT+12'>(UTC-12:00) International Date Line West (Etc/GMT+12)</option>
          <option value='Etc/GMT+11'>(UTC-11:00) Coordinated Universal Time-11 (Etc/GMT+11)</option>
          <option value='Pacific/Honolulu'>(UTC-10:00) Hawaii (Pacific/Honolulu)</option>
          <option value='America/Anchorage'>(UTC-09:00) Alaska (America/Anchorage)</option>
          <option value='America/Santa_Isabel'>(UTC-08:00) Baja California (America/Santa_Isabel)</option>
          <option value='America/Los_Angeles'>(UTC-08:00) Pacific Time (US and Canada) (America/Los_Angeles)</option>
          <option value='America/Chihuahua'>(UTC-07:00) Chihuahua, La Paz, Mazatlan (America/Chihuahua)</option>
          <option value='America/Phoenix'>(UTC-07:00) Arizona (America/Phoenix)</option>
          <option value='America/Denver'>(UTC-07:00) Mountain Time (US and Canada) (America/Denver)</option>
          <option value='America/Guatemala'>(UTC-06:00) Central America (America/Guatemala)</option>
          <option value='America/Chicago'>(UTC-06:00) Central Time (US and Canada) (America/Chicago)</option>
          <option value='America/Regina'>(UTC-06:00) Saskatchewan (America/Regina)</option>
          <option value='America/Mexico_City'>(UTC-06:00) Guadalajara, Mexico City, Monterey (America/Mexico_City)</option>
          <option value='America/Bogota'>(UTC-05:00) Bogota, Lima, Quito (America/Bogota)</option>
          <option value='America/Indiana/Indianapolis'>(UTC-05:00) Indiana (East) (America/Indiana/Indianapolis)</option>
          <option value='America/New_York'>(UTC-05:00) Eastern Time (US and Canada) (America/New_York)</option>
          <option value='America/Caracas'>(UTC-04:30) Caracas (America/Caracas)</option>
          <option value='America/Halifax'>(UTC-04:00) Atlantic Time (Canada) (America/Halifax)</option>
          <option value='America/Asuncion'>(UTC-04:00) Asuncion (America/Asuncion)</option>
          <option value='America/La_Paz'>(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/La_Paz)</option>
          <option value='America/Cuiaba'>(UTC-04:00) Cuiaba (America/Cuiaba)</option>
          <option value='America/Santiago'>(UTC-04:00) Santiago (America/Santiago)</option>
          <option value='America/St_Johns'>(UTC-03:30) Newfoundland (America/St_Johns)</option>
          <option value='America/Sao_Paulo'>(UTC-03:00) Brasilia (America/Sao_Paulo)</option>
          <option value='America/Godthab'>(UTC-03:00) Greenland (America/Godthab)</option>
          <option value='America/Cayenne'>(UTC-03:00) Cayenne, Fortaleza (America/Cayenne)</option>
          <option value='America/Argentina/Buenos_Aires'>(UTC-03:00) Buenos Aires (America/Argentina/Buenos_Aires)</option>
          <option value='America/Montevideo'>(UTC-03:00) Montevideo (America/Montevideo)</option>
          <option value='Etc/GMT+2'>(UTC-02:00) Coordinated Universal Time-2 (Etc/GMT+2)</option>
          <option value='Atlantic/Cape_Verde'>(UTC-01:00) Cape Verde (Atlantic/Cape_Verde)</option>
          <option value='Atlantic/Azores'>(UTC-01:00) Azores (Atlantic/Azores)</option>
          <option value='Africa/Casablanca'>(UTC+00:00) Casablanca (Africa/Casablanca)</option>
          <option value='Atlantic/Reykjavik'>(UTC+00:00) Monrovia, Reykjavik (Atlantic/Reykjavik)</option>
          <option value='Europe/London'>(UTC+00:00) Dublin, Edinburgh, Lisbon, London (Europe/London)</option>
          <option value='Etc/GMT'>(UTC+00:00) Coordinated Universal Time (Etc/GMT)</option>
          <option value='Europe/Berlin'>(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Berlin)</option>
          <option value='Europe/Paris'>(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Paris)</option>
          <option value='Africa/Lagos'>(UTC+01:00) West Central Africa (Africa/Lagos)</option>
          <option value='Europe/Budapest'>(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Budapest)</option>
          <option value='Europe/Warsaw'>(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Warsaw)</option>
          <option value='Africa/Windhoek'>(UTC+01:00) Windhoek (Africa/Windhoek)</option>
          <option value='Europe/Istanbul'>(UTC+02:00) Athens, Bucharest, Istanbul (Europe/Istanbul)</option>
          <option value='Europe/Kiev'>(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius (Europe/Kiev)</option>
          <option value='Africa/Cairo'>(UTC+02:00) Cairo (Africa/Cairo)</option>
          <option value='Asia/Damascus'>(UTC+02:00) Damascus (Asia/Damascus)</option>
          <option value='Asia/Amman'>(UTC+02:00) Amman (Asia/Amman)</option>
          <option value='Africa/Johannesburg'>(UTC+02:00) Harare, Pretoria (Africa/Johannesburg)</option>
          <option value='Asia/Jerusalem'>(UTC+02:00) Jerusalem (Asia/Jerusalem)</option>
          <option value='Asia/Beirut'>(UTC+02:00) Beirut (Asia/Beirut)</option>
          <option value='Asia/Baghdad'>(UTC+03:00) Baghdad (Asia/Baghdad)</option>
          <option value='Europe/Minsk'>(UTC+03:00) Minsk (Europe/Minsk)</option>
          <option value='Asia/Riyadh'>(UTC+03:00) Kuwait, Riyadh (Asia/Riyadh)</option>
          <option value='Africa/Nairobi'>(UTC+03:00) Nairobi (Africa/Nairobi)</option>
          <option value='Asia/Tehran'>(UTC+03:30) Tehran (Asia/Tehran)</option>
          <option value='Europe/Moscow'>(UTC+04:00) Moscow, St. Petersburg, Volgograd (Europe/Moscow)</option>
          <option value='Asia/Tbilisi'>(UTC+04:00) Tbilisi (Asia/Tbilisi)</option>
          <option value='Asia/Yerevan'>(UTC+04:00) Yerevan (Asia/Yerevan)</option>
          <option value='Asia/Dubai'>(UTC+04:00) Abu Dhabi, Muscat (Asia/Dubai)</option>
          <option value='Asia/Baku'>(UTC+04:00) Baku (Asia/Baku)</option>
          <option value='Indian/Mauritius'>(UTC+04:00) Port Louis (Indian/Mauritius)</option>
          <option value='Asia/Kabul'>(UTC+04:30) Kabul (Asia/Kabul)</option>
          <option value='Asia/Tashkent'>(UTC+05:00) Tashkent (Asia/Tashkent)</option>
          <option value='Asia/Karachi'>(UTC+05:00) Islamabad, Karachi (Asia/Karachi)</option>
          <option value='Asia/Colombo'>(UTC+05:30) Sri Jayewardenepura Kotte (Asia/Colombo)</option>
          <option value='Asia/Kolkata'>(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)</option>
          <option value='Asia/Kathmandu'>(UTC+05:45) Kathmandu (Asia/Kathmandu)</option>
          <option value='Asia/Almaty'>(UTC+06:00) Astana (Asia/Almaty)</option>
          <option value='Asia/Dhaka'>(UTC+06:00) Dhaka (Asia/Dhaka)</option>
          <option value='Asia/Yekaterinburg'>(UTC+06:00) Yekaterinburg (Asia/Yekaterinburg)</option>
          <option value='Asia/Yangon'>(UTC+06:30) Yangon (Asia/Yangon)</option>
          <option value='Asia/Bangkok'>(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)</option>
          <option value='Asia/Novosibirsk'>(UTC+07:00) Novosibirsk (Asia/Novosibirsk)</option>
          <option value='Asia/Krasnoyarsk'>(UTC+08:00) Krasnoyarsk (Asia/Krasnoyarsk)</option>
          <option value='Asia/Ulaanbaatar'>(UTC+08:00) Ulaanbaatar (Asia/Ulaanbaatar)</option>
          <option value='Asia/Shanghai'>(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi (Asia/Shanghai)</option>
          <option value='Australia/Perth'>(UTC+08:00) Perth (Australia/Perth)</option>
          <option value='Asia/Singapore'>(UTC+08:00) Kuala Lumpur, Singapore (Asia/Singapore)</option>
          <option value='Asia/Taipei'>(UTC+08:00) Taipei (Asia/Taipei)</option>
          <option value='Asia/Irkutsk'>(UTC+09:00) Irkutsk (Asia/Irkutsk)</option>
          <option value='Asia/Seoul'>(UTC+09:00) Seoul (Asia/Seoul)</option>
          <option value='Asia/Tokyo'>(UTC+09:00) Osaka, Sapporo, Tokyo (Asia/Tokyo)</option>
          <option value='Australia/Darwin'>(UTC+09:30) Darwin (Australia/Darwin)</option>
          <option value='Australia/Adelaide'>(UTC+09:30) Adelaide (Australia/Adelaide)</option>
          <option value='Australia/Hobart'>(UTC+10:00) Hobart (Australia/Hobart)</option>
          <option value='Asia/Yakutsk'>(UTC+10:00) Yakutsk (Asia/Yakutsk)</option>
          <option value='Australia/Brisbane'>(UTC+10:00) Brisbane (Australia/Brisbane)</option>
          <option value='Pacific/Port_Moresby'>(UTC+10:00) Guam, Port Moresby (Pacific/Port_Moresby)</option>
          <option value='Australia/Sydney'>(UTC+10:00) Canberra, Melbourne, Sydney (Australia/Sydney)</option>
          <option value='Asia/Vladivostok'>(UTC+11:00) Vladivostok (Asia/Vladivostok)</option>
          <option value='Pacific/Guadalcanal'>(UTC+11:00) Solomon Islands, New Caledonia (Pacific/Guadalcanal)</option>
          <option value='Etc/GMT-12'>(UTC+12:00) Coordinated Universal Time+12 (Etc/GMT-12)</option>
          <option value='Pacific/Fiji'>(UTC+12:00) Fiji, Marshall Islands (Pacific/Fiji)</option>
          <option value='Asia/Magadan'>(UTC+12:00) Magadan (Asia/Magadan)</option>
          <option value='Pacific/Auckland'>(UTC+12:00) Auckland, Wellington (Pacific/Auckland)</option>
          <option value='Pacific/Tongatapu'>(UTC+13:00) Nuku'alofa (Pacific/Tongatapu)</option>
          <option value='Pacific/Apia'>(UTC+13:00) Samoa (Pacific/Apia)</option>
        </select>
      </div>
      <div v-if="organization.autoReplyAway" class="form-group" style="margin-top: 10px">
        <label>{{ $t('settings.auto_reply.away.schedule') }}</label>
        <div>
          <button @click="this.organizationSchedule.push({type: 10,from: '09:00',to: '18:30'})" class="button secondary" type="submit">{{ $t('settings.auto_reply.away.add') }}</button>
        </div>
      </div>
      <div v-if="organization.autoReplyAway" class="form-group-columns">
        <OrganizationSchedulePicker v-for="(schedule, index) in this.organizationSchedule" :key="index" :element="index" :elementData="schedule" />
      </div>
      <div v-if="organization.autoReplyAway" class="form-group" style="margin-top: 10px">
        <button @click="onClickSaveSchedule" class="button" type="submit">{{ $t('settings.auto_reply.away.save') }}</button>
      </div>
    </div>
<!--    <div v-if="isWABusiness()" class="wabusiness-overlay"></div>-->
  </div>
</template>

<script>
import Switch from '@/components/common/Switch'
import OrganizationSchedulePicker from '@/components/common/OrganizationSchedulePicker'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionAutoReply',
  components: {
    Switch,
    OrganizationSchedulePicker
  },
  data () {
    return {
      timer: null,
      selectedTemplate: null
    }
  },
  computed: mapGetters(['organization', 'organizationSchedule', 'templates']),
  methods: {
    ...mapActions(['fetchOrganization', 'updateOrganization', 'fetchOrganizationSchedule', 'updateOrganizationSchedule',
      'getSupportContact', 'fetchConversation', 'fetchContacts', 'fetchTemplates', 'emptyTemplates'
    ]),
    onClickSupport () {
      this.getSupportContact().then(contact => {
        this.fetchContacts({ filter: 1 }).then(() => {
          this.fetchConversation({ contact: contact, messageId: null })
        })
      })
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    onChangeSwitch (value, key) {
      this.organization[key] = value

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onChangeValue (event) {
      clearTimeout(this.timer)

      // if (event.currentTarget.value === '' || event.currentTarget.value === '0') {
      //   return
      // }

      this.timer = setTimeout(() => {
        this.updateOrganization(this.organization).then(() => {
          this.$toast.open({
            message: this.$t('form.saved')
          })
        })
      }, 1000)
    },
    onClickSaveSchedule () {
      this.updateOrganizationSchedule(this.organizationSchedule).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    }
  },
  created () {
    this.fetchOrganization().then(() => {
      this.fetchOrganizationSchedule().then(() => {
        this.fetchTemplates({ query: '', all: false })
      })
    })
  },
  beforeUnmount () {
    this.emptyTemplates()
  }
}
</script>

<style scoped>
h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

#close-minutes {
  width: 50px;
  text-align: right;
  display: inline-block;
  margin: 0 10px;
}

.form-group button.secondary {
  color: #858687;
  background-color: #ffffff;
  border: 1px solid #858687;
  float: unset;
}

.form-group button.secondary:hover {
  color: #ffffff;
  background-color: #858687;
}

.form-group-columns {
  display: flex;
  flex-wrap: wrap;
}

.form-group-columns .form-group {
  padding: 30px 20px 20px 20px;
  border-radius: 3px;
  border: 1px solid #EDEFF1;
  margin-bottom: 10px;
  margin-right: 10px;
}

.form-group-columns .form-group select {
  min-width: 180px;
  margin-bottom: 10px;
}

.form-group-columns .form-group .fa-trash-alt {
  position: absolute;
  top: 15px;
  right: 20px;
}

.form-group input[type=radio]{
  width: auto;
}

.welcome-radios {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
}
</style>
