<template>
  <div>
    <!-- FIXME: Alerts priority order -->
    <ConnectorsAlert />
    <ImportantAlert />
    <UnpaidInvoiceAlert />
    <MigrationAlert />
  </div>
</template>

<script>
import ConnectorsAlert from '@/components/home/alerts/ConnectorsAlert.vue'
import UnpaidInvoiceAlert from '@/components/home/alerts/UnpaidInvoiceAlert.vue'
import ImportantAlert from '@/components/home/alerts/ImportantAlert.vue'
import MigrationAlert from '@/components/home/alerts/MigrationAlert.vue'

export default {
  name: 'Alerts',
  methods: {
  },
  components: {
    MigrationAlert,
    ImportantAlert,
    UnpaidInvoiceAlert,
    ConnectorsAlert
  }
}
</script>

<style scoped>

</style>
